@keyframes loading {
    from {
        transform: rotate(0)
    }

    to {
        transform: rotate(360deg)
    }
}

%loading,
.loading {
    --action-text-color: transparent;
    --icon-color: transparent;
    
    position: relative;

    &:after {
        content: "";
        position: absolute;
        display: block;
        height: 2rem;
        width: 2rem;
        inset: 50% 50% auto auto;
        margin: -1rem -1rem 0 0;
        background-image: var(--loading-icon);
        background-position: center center;
        background-repeat: no-repeat;
        animation: loading 0.7s infinite linear;
        animation-timing-function: steps(10, end);
        border-right-color: transparent;
        border-top-color: transparent;
        z-index: 1;
    }

}

.fui-loading {
    @extend %loading;
}