@use "../mixins/mq";
@use "../_base/typography";

.nav--main-nav {
	--link-color: currentColor;
	--link-text-decoration: none;
	--link-text-decoration-hover: none;
	--icon-width: 2.4rem;
	--icon-height: var(--icon-width);
	--nav-toggle-width: 3rem;
	--mobile-menu-bg-color: var(--color-white);

	font-weight: 700;



	@include mq.mq($until: large) {
		max-height: var(--nav-height, 100%);
		overflow: hidden;
		padding: 15rem 0 3.5rem;
		font-size: 1.8rem;

		.is-active {
			color: var(--color-black);
		}

		.nav {
			&__back-link {
				--link-color: var(--color-black);
				--icon-width: 2.4rem;

				min-height: 10rem;
				display: block;
				width: 100%;
			}

			&__current-link {
				--icon-display: inline-block;
				--icon-margin: 0 0 0 1rem;
				--link-font-weight: bold;
				--link-color: var(--color-black);

				display: block;
			}

			&__wrap {
				display: none;

				&--parent {
					display: block;
				}

				&--child {
					--link-font-weight: normal;
					--link-color: var(--color-grey-500);
					--icon-width: 1.5rem;

					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					min-height: 100vh;
					height: 100%;
					transform: var(--nav-wrap-transform, translateX(100%));
					transition: transform var(--animation-duration) var(--animation-ease);
					visibility: hidden;
					will-change: transform;
					background-color: var(--mobile-menu-bg-color);
					z-index: 2;


					// fix for mobile
					&:after {
						content: "";
						display: block;
						height: 20rem;
					}
				}

				&--sub-child {
					display: none;
					width: 100%;
					order: 1;
					padding: 0;
					border: none;
					opacity: 0;
					transition: max-height var(--animation-duration) var(--animation-ease), opacity var(--animation-duration) var(--animation-ease);
					font-weight: normal;
					font-family: var(--heading-font-family);
					font-size: var(--small-font-size);
					line-height: var(--small-line-height);
					letter-spacing: var(--small-letter-spacing);

					&.nav__wrap--current {
						opacity: 1;
						display: block;

					}
				}

				// status

				&--visible {
					visibility: visible;
					display: block;
				}

				&--current {
					--nav-wrap-transform: translateX(0);



				}




			}

			&__item {
				--nav-link-width: 100%;

				display: flex;
				gap: 0 1rem;
				flex-wrap: wrap;
				justify-content: space-between;
				margin: 0 var(--wrapper-gap) 1.5rem;

				&--has-children {
					--nav-link-width: calc(100% - 4rem);
				}

				&--sub-child {
					margin: 2rem 1rem;

				}

				&--back-link {
					position: relative;
					padding: 0 var(--wrapper-gap);
					margin: 0;
					z-index: 1;
					display: block;
					border-bottom: 0.1rem solid var(--color-grey-400);
					margin-bottom: 5rem;
				}

				&--current-link {
					padding: 0 var(--wrapper-gap);
					margin: 0 0 2.5rem;
					display: block;
				}
			}

			&__link {
				width: var(--nav-link-width, 100%);
			}

			&__toggle {
				align-items: center;
				display: flex;
				justify-content: flex-end;
				width: var(--nav-toggle-width);
				color: var(--link-color);

				.icon-minus {
					display: var(--icon-minus-display, none);
				}

				.icon-plus {
					display: var(--icon-plus-display, block);
				}

				&[aria-expanded="true"] {
					--icon-minus-display: block;
					--icon-plus-display: none;
				}

			}
		}
	}

	/* menu large */
	@include mq.mq($from: large) {
		--nav-parent-link-spacing: 1.6rem;
		--link-color-hover: var(--color-secondary-900);
		--link-color-focus: var(--color-secondary-900);

		.is-active {
			color: var(--color-secondary-900);
		}

		.nav {
			&__current-link {
				--icon-display: inline;
				--icon-margin: 0 0 0 1rem;
				--icon-vertical-align: middle;
				--link-color: var(--color-secondary-900);
		

				&:hover,
				&:focus {
					--link-color: var(--link-color-focus);
				}

				display: block;
				font-family: var(--heading-font-family);
				font-size: var(--font-size-h6);
				line-height: 1.2;
				font-weight: var(--heading-font-weight);
				letter-spacing: var(--letter-spacing-h6);
			}


			&__wrap {
				&--parent {
					display: flex;
					justify-content: space-between;
					border-right: .1rem solid var(--color-primary-900);

				}

				&--child {
					font-size: 1.5rem;
					display: var(--nav-wrap-child-display, none);
					position: absolute;
					background-color: var(--color-white);
					padding: 5rem var(--wrapper-gap);
					left: 0;
					right: 0;
					top: calc(100% + .1rem);
					z-index: -1;
					gap: 3rem 1.5rem;
					grid-template-columns: repeat(5, minmax(0, 25.8rem));
					width: auto;
					justify-content: center;
					color: var(--color-primary-800);

				}

				&--sub-child {
					display: none;
				}

			}

			&__item {
				&--parent {
					flex: 1;

					&:hover {
						--nav-wrap-child-display: grid;
						--nav-link-parent-helper: block;

					}

				}

				&--has-children {
					&:hover {
						--nav-link-parent-border-color: var(--color-white);

					}
				}

				&--current-link,
				&--back-link {
					display: none;
				}

				&--child {
					animation: mega-menu-link-fade-in .2s var(--animation-ease) normal forwards;
					transform: translateY(-2rem);
					opacity: var(--animation-start-opacity,0);

					@for $i from 2 through 10 {
						&:nth-child(#{$i}) {
							animation-delay: #{$i * 33}ms;
						}
					}
				}

			}

			&__link {
				display: block;

				&--parent {
					white-space: nowrap;
					height: 8.6rem;
					display: grid;
					align-items: center;
					text-align: center;
					border-left: .1rem solid var(--color-primary-900);
					padding-inline: 2rem;
					border-bottom: var(--nav-link-parent-border-color, transparent) .1rem solid;
					margin-bottom: -1px;
					transition: color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
					position: relative;

					&:after {
						content: "";
						border-radius: 50% 50% 0 100% / 100% 100% 0 0;
						display: var(--nav-link-parent-helper, none);
						opacity: .2;
						position: absolute;
						inset: 2rem -8rem -.1rem;
						z-index: 1;
						
						// background-color: red;

					}

					&.open {
						--nav-link-parent-border-color: var(--color-white);

						~.nav__wrap {
							--nav-wrap-child-display: grid;
						}
					}
				}

				&--child {
					&:hover,
					&:focus {
						color: var(--link-color-focus);
					}

				}
			}


			&__toggle {
				display: none;
			}

		}
	}
}


@keyframes mega-menu-link-fade-in {
	from {
		transform: translateY(-2rem);
		opacity: 0;

	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}