@use "../mixins/mq";

$columns: 12;
$sizes: (
		'sm',
		'md',
		'lg'
);

%container,
.container {
	width: 100%;
	max-width: var(--container-max-width);
	position: var(--container-position,static);
	margin-inline: auto;

	&--fluid {
		--container-width: none;
	}
	
	&--skinny {
		--container-max-width: var(--container-max-skinny-width);
	}
	
	&--slim {
		--container-max-width: var(--container-max-slim-width);
	}
	
	&--narrow {
			--container-max-width: var(--container-max-narrow-width);
	}
}


.middle {
	width: 100%;
	max-width: var(--middle-max-width,100%);
	position: var(--middle-position,static);
	margin-inline: auto;

	&--small {
		--middle-max-width: var(--middle-max-width-small);
	}

	&--medium {
		--middle-max-width: var(--middle-max-width-medium);
	}

	&--large {
		--middle-max-width: var(--middle-max-width-large);
	}
}



.wrapper {
	position: relative;
	width: 100%;
	padding-left: var(--wrapper-gap);
	padding-right: var(--wrapper-gap);

	.wrapper {
		--wrapper-gap: 0;
	}
}

.row-flex {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.row--v-align-center {
	align-items: center;
}

.row--h-align-center {
	justify-content: center;
}

.row {
	display: grid;
	grid-template-columns: repeat($columns, 1fr);
	grid-column-gap: var(--grid-gap);
	justify-content: center;
	grid-template-rows: auto;
	align-items: start;
}

.row--gutters-y {
	grid-row-gap: var(--grid-gap);
}

.row--reverse {
	@include mq.mq($from: medium) {
		direction: rtl;
	}

	* {
		direction: ltr;
		text-align: left;
	}
}

.col--half {
	width: 50%;
}

.col {
	grid-column-end: span 12;

	&--center-content {
		height: 100%;
		display: grid;
		justify-content: center;
		align-items: center;
	}
}




@mixin generate-columns($columns, $size, $breakpointStart: null, $breakpointEnd: null) {
	@if $breakpointStart {
		@include mq.mq($from: $breakpointStart) {
			@for $i from 1 through $columns {
				.col-#{$size}-#{$i} {
					grid-column-end: span $i;
				}

				.offset-#{$size}-#{$i} {
					grid-column-start: $i + 1
				}

				.row-start-#{$size}-#{$i} {
					grid-row-start: $i
				}

			}
		}

	} @else {
		@for $i from 1 through $columns {

			.col-#{$size}-#{$i} {
				grid-column-end: span $i;
			}

			.offset-#{$size}-#{$i} {
				grid-column-start: $i + 1;
			}
		}
	}
}

@each $size in $sizes {
	@if $size == 'sm' {
		@include generate-columns($columns, $size);
	} @else if $size == 'md' {
		@include generate-columns($columns, $size, medium, large);
	} @else if $size == 'lg' {
		@include generate-columns($columns, $size, large);
	}
}

.offset-md-0 {
	@include mq.mq($from: medium) {
		grid-column-start: 1;
	}
}

.offset-lg-0 {
	@include mq.mq($from: large) {
		grid-column-start: 1;
	}
}
