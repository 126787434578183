/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: inherit;
  font-family: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  text-align: inherit;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, iframe {
  display: block;
}

iframe {
  border: 0;
}

body {
  line-height: 1;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
  font-family: inherit;
  line-height: inherit;
  font-weight: inherit;
}

* {
  box-sizing: border-box;
}

address {
  font-style: normal;
}

select {
  font-family: inherit;
  appearance: none;
}

/*
select, textarea, input, a, button {
  outline-color: var(--outline-color,currentColor);
  outline-offset: 0.3rem;
}
*/


:focus-visible {
  outline-offset: var(--outline-offset,.5rem);
  outline: var(--outline-color, currentColor) solid var(--outline-width, .2rem);

  // box-shadow: 0 0 1rem 0 var(--color-black);
}

img {
  user-select: none;
}

legend {
  display: block;
}

input,
select,
textarea,
button {
  padding: 0;
  margin: 0;
  color: inherit;
  font: inherit;
  border: none;
  border-radius: 0;
  background: none;
  letter-spacing: inherit;
}

select:not([multiple], [size]) {
  background-position: right var(--input-padding-inline) center;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  background-image: var(--input-select-arrow-bg);
  padding-right: calc( var(--input-padding-inline) + 2rem );
  appearance: none;
}

::-webkit-search-cancel-button {
  appearance: none;
}

::-webkit-search-results-button {
  appearance: none;
}

::-webkit-inner-spin-button, 
::-webkit-outer-spin-button { 
  appearance: none;
  margin: 0; 
}

dialog {
  border: none;
  background-color: unset;
  padding: 0;
  margin: 0;
}

*, *:before, *:after {
  box-sizing: inherit;
}


details {
  ::-webkit-details-marker,
  ::marker {
    display: none;
    content: '';
  }
}

