@use "../mixins/mq";

.iframe-wrapper {
	padding: 0;
}

.iframe-container {
	position: relative;
	width: 100%;
	aspect-ratio: 16/9;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	iframe,
	div {
		position: absolute !important;
		width: 100% !important;
		height: 100% !important;
	}
}

.iframe__poster {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 1;
	transition: all .3s cubic-bezier(.25,.46,.45,.75);

	&--playing {
		opacity: 0;
		z-index: 0;
	}

	img {
		display: block;
		width: 100%	;
		height: 100%;
		object-fit: cover;
	}
}

.iframe__play {
	--icon-width: 2rem;
	--icon-height: 2rem;

	position: absolute;
	left: 50%;
	top: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	padding: 0;
	transform: translate3d(-50%, -50%, 0);
	z-index: 2;
	cursor: pointer;
	will-change: background-color;
	border-radius: 50%;
	transition: background-color 0.25s ease;
	background-color: var(--color-primary-900);
	color: var(--white);

	@include mq.mq($from: large) {
		--icon-width: 3.2rem;
		--icon-height: 3.2rem;

		width: 120px;
		height: 120px;
	}

	.icon {
		transform: translateX(2px);
		color: var(--color-white);

		@include mq.mq($from: large) {
			transform: translateX(5px);
		}
	}

	&:hover {
		background-color: var(--color-secondary-900);
	}
}
