$fontPath: '../../webfonts/';

// tmp solution, need to be self hosted
@import "https://p.typekit.net/p.css?s=1&k=kit8fjx&ht=tk&f=9785.9787&a=189274231&app=typekit&e=css";

@font-face {
	font-family: effra;
	src: url('https://use.typekit.net/af/2dce9d/00000000000000003b9b489b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: effra;
	src: url('https://use.typekit.net/af/23ddd7/00000000000000003b9b489e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff2');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

/*
Weight name mapping:
100	Thin (Hairline)
200	Extra Light (Ultra Light)
300	Light
400	Normal
500	Medium
600	Semi Bold (Demi Bold)
700	Bold
800	Extra Bold (Ultra Bold)
900	Black (Heavy)
*/

@font-face {
	font-display: swap;
	font-family: effra-web;
 src: url('#{$fontPath}effra/effra-bold-webfont.woff2') format('woff2');
	font-weight: 700;
}

@font-face {
	font-display: swap;
	font-family: effra-web;
	src: url('#{$fontPath}effra/effra-regular-webfont.woff2') format('woff2');
	font-weight: 400;
}