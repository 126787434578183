@use "../mixins/mq";

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

.splide {
  --splide-accent-color: var(--color-secondary-900);

  position: relative;
  visibility: hidden;

  &.is-initialized, &.is-rendered {
    visibility: visible;
  }

  &--rtl {
    direction: rtl;
  }

  &--article-teaser {
    margin-right: 5rem;
  }

  &:not(.is-overflow) .splide__pagination {
    display: none;
  }
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;

  &--draggable {
    user-select: none;
  }

  &--fade {
    > .splide__list > .splide__slide {
      margin: 0 !important;
      opacity: 0;
      z-index: 0;

      &.is-active {
        opacity: 1;
        z-index: 1;
      }
    }
  }

  &--ttb > .splide__list {
    display: block;
  }
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;

  .splide.is-initialized:not(.is-active) & {
    display: block;
  }
}

.splide__aside-controls { 
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  height: 100%;
  justify-content: center;
  display: none;

  @include mq.mq($from: medium) {
    display: flex;
  }
}

.splide__pagination {
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  pointer-events: none;
  list-style: none;
  margin: var(--splide-pagination-margin,2.5rem 0 0);
  padding: 0;
  gap: .5rem;

  li {
    display: inline-block;
    line-height: 1;
    list-style-type: none;
    margin: 0;
    pointer-events: auto;
    padding: 0;
  }

  &-page {
    color: var(--splide-accent-color);
    width: 1.4rem;
    aspect-ratio: 1/1;
    display: grid;
    justify-content: center;
    place-items: center;
    position: relative;

    &:after {
      content: "";
      display: block;
      border-radius: 50%;
      border: currentColor .2rem solid;
      position: absolute;
      width: var(--slider-pagination-page-width, .4rem);
      aspect-ratio: 1/1;
      transition: width var(--animation-duration) var(--animation-ease), height var(--animation-duration) var(--animation-ease);
    }

    &.is-active {
      --slider-pagination-page-width: 1.4rem;
    }

    &:hover {
      @include mq.mq($from: medium) {
        --slider-pagination-page-width: 1.4rem;
      }
    }

    &:focus {
      z-index: 1;
    }
  }

  @include mq.mq($from: medium) {
    display: none;
  }
}

.splide__progress {
  background-color: var(--color-white-100-transparent);

  > div {
    height: .1rem;
    background-color: var(--splide-accent-color);
  }
}



.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
  padding: 0;

  img {
    vertical-align: bottom;
  }


}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999999;
  border-left-color: transparent;
  border-radius: 50%;
  contain: strict;
  display: inline-block;
  height: 20px;
  margin: auto;
  position: absolute;
  inset: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle {
  &.is-active .splide__toggle-play, .splide__toggle-pause {
    display: none;
  }

  &.is-active .splide__toggle-pause {
    display: inline;
  }
}

.splide__arrows {
  display: none;
  gap: 1.5rem;



  &--vertical {
    flex-direction: column;
    margin: auto 0;
  }



  @include mq.mq($from: medium) {
    display: flex;


  }

  &--hide {
    display: none;
  }
}

.splide__arrow {
  border-radius: 50%;
  border: 0.2rem solid var(--color-secondary-900);
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all var(--animation-duration) var(--animation-ease);

  &:hover {
    transform: scale(1.16);
  }

  &:focus, &:hover {
    --link-color: var(--color-secondary-900);
  }

  &[disabled] {
    opacity: 0.4;

    &:hover {
      transform: none;
    }
  }
}

.splide__track--autoheight-mobile {
  .splide__list {
    @include mq.mq($until: medium) {
      align-items: flex-start !important;
    }
  }

  .splide__slide {
    &:not(.is-active) {
      @include mq.mq($until: medium) {
        height: 0 !important;
      }
    }
  }
}