@use "sass:color";
@use "../_config/colors";
@use "../mixins/mq";

.figure {
	position: var(--figure-position,static);
	overflow: hidden;

	&__play {
		--icon-position: absolute;
		--icon-transform: translate(-50%, -50%);

		left: 50%;
		top: 50%;
		border-radius: 50%;
		box-shadow: 0 0  0 50rem var(--color-black-transparent);
	}
}

picture {
	display: block;
}

img,
video {
	display: block;
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

img {
	image-rendering: -webkit-optimize-contrast;
	backface-visibility: hidden;
	object-position: var(--focalpoint);
	position: var(--img-position,static);
	object-fit: cover;
	border-radius: var(--img-border-radius, none);
	background-color: var(--img-bg-color, none);
	inset: 0;
	min-width: var(--img-min-width, none);
	width: var(--img-width, auto);
	transform: var(--img-transform);
	transition: transform var(--animation-duration) var(--animation-ease), object-view-box var(--animation-duration) var(--animation-ease);
	object-view-box: var(--img-object-view-box, none);
	aspect-ratio: var(--img-aspect-ratio, none);
}

svg {
	max-width: 100%;
	height: auto;
}

video {
	width: 100%;
	object-position: var(--focalpoint);
	object-fit: cover;
}


::-webkit-media-controls,
::-webkit-media-controls-enclosure,
::-webkit-media-controls-overlay-play-button {
	display: none !important;
	appearance: none !important;

}
