@use "../mixins/mq";

.contact {
    margin-right: var(--wrapper-gap-negative);
    border-top: var(--color-grey-400) .1rem solid;
    padding-block: 5rem;
    display: flex;
    gap: 1.5rem;
    justify-content: space-between;

    &:last-child {
        border-bottom: var(--color-grey-400) .1rem solid;
    }

    &__photo {
        flex-shrink: 0;
    }

    @include mq.mq($from: large) {
        margin-right: 0;
    }

}