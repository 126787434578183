@use "../mixins/mq";
@use "../atoms/grid";

.cta {
	.container:before,
	.figure {
		@extend %container;

		position: absolute;
		left: 50%;
		top: 50%;
		height: 100.5%;
		transform: translate3d(-50%, -50%, 0);
	}

	.container {
		padding-top: 4rem;
		padding-bottom: 4rem;

		&:before {
			display: block;
			height: 100%;
			content: '';
			background-color: var(--color-primary-900);
		}

		@include mq.mq($from: large) {
			padding-top: 12rem;
			padding-bottom: 12rem;
		}
	}

	&__icon,
	&__icon svg {
		width: 4rem;
		margin-bottom: 2rem;
	}
}
