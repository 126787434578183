@use "../mixins/mq";
@use "../_base/typography";
@use "../atoms/action";


.footer {
  position: relative;
  z-index: 1;


  &__grid {
    display: grid;
    gap: 3.5rem;

    &--bottom {
      gap: 3rem 0;
    }
  }

  &__address {
    line-height: 150%;
    letter-spacing: 0.028rem;

    address {
      margin-bottom: 1.5rem;
      color: var(--color-primary-800);
    }

    .link {
      --link-font-weight: 400;
      --link-color: var(--color-primary-800);

      &:hover {
        --link-color: var(--color-secondary-900);
      }
    }
  }

  &__opening {
    --spacing-text-bottom: 1.5rem;

    line-height: 150%;
    letter-spacing: 0.028rem;
    color: var(--color-primary-800);
  }

  &__col--privacy {
    display: none;
  }

  @include mq.mq($from: medium) {
    &__grid {
      &--content {
        gap: 0 11%;
        grid-template-columns: 7fr 5fr;
      }

      &--bottom {
        grid-template-columns: 1fr auto;
        align-items: center;
      }
    }

    &__col--privacy {
      display: block;
    }
  }
}