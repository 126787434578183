@use "../mixins/mq";

.cmp-staff__container {
  display: grid;
  gap: 5rem;

  @include mq.mq($from: medium){
    gap: 7.5rem;
    grid-template-columns: repeat(3, 1fr);

  }
}