@use "../mixins/mq";

.nav--social-nav {
	--icon-width: 2.4rem;
	--icon-height: 2.4rem;
	--icon-color: var(--color-grey-500);

	.nav {
		&__wrap {
			display: flex;
			gap: 2.5rem;
			justify-content: center;
		}

		&__link {
			display: grid;

			.icon {
				transition: all var(--animation-duration) var(--animation-ease);
			}

			&:hover {
				.icon {
					--icon-color: var(--color-secondary-900);
				}
			}


		}

		&__item {
			flex-shrink: 0;
		}


	}
}