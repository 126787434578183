@use "../mixins/mq";
@use "../atoms/grid";

.epaper {
  display: block;

  &__item {
    text-decoration: none;
    border-top: 0.1rem solid var(--color-grey-400);
    border-bottom: 0.1rem solid var(--color-grey-400);
    padding: 3rem 0;
    margin-right: var(--wrapper-gap-negative);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;


    &:not(:first-child) {
      border-top: none;
      margin-top: 0;

    }



    &-image {
      height: auto;
      background: var(--color-grey-300);
      padding: 1.9rem 1.3rem 1.8rem 1.4rem;
      min-width: 9.4rem;


    }

    &-text {
      &-title {
        color: var(--color-black);
        margin-bottom: 0;
      }

      .action {
        margin-top: 1.5rem;

      }
    }
  }

  @include mq.mq($from: large) {
    &:not(:first-child) {
      margin-top: 7.5rem;
    }

    &__item {
      border: none;
      padding: 0;
      margin: 0;
      flex-direction: row;
      gap: 9rem;
      justify-content: flex-start;




      &-image {
        padding: 6.1rem 4rem;
        min-width: 25.8rem;

        img {
          width: 100%;
        }

      }

      &-text {
        .action {
          margin-top: 2.5rem;
          justify-content: left;
        }
      }
    }

  }

}