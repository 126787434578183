@use "../mixins/mq";
@use "../_base/typography";
@use "../atoms/action";

.footer__top {
  background: var(--color-primary-800);
  color: var(--color-white);
  padding: 3rem 0;

  @include mq.mq($from: medium) {
    padding: 5rem 0;
  }
}

.footer__membershipinfos {
  margin: 2.5rem 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;


  &-item {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    letter-spacing: 0.028rem;

    --icon-width: 2.5rem;
    --icon-height: 2.5rem;

  }

  @include mq.mq($from: medium) {
    flex-direction: row;
    gap: 9.9rem;
  }
}