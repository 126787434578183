@use "../mixins/mq";

.seminar {
  &__description {
    margin-top: 2.5rem;

    p {
      --spacing-text-bottom: 1.5rem;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 36.7rem;

    .h2 {
      margin: 0;
    }
  }

  &__address {
    max-width: 36.7rem;
    margin-top: 4rem;

    &-title {
      margin-bottom: 1rem;
      font-size: 1.8rem;
      line-height: 150%;
      letter-spacing: 0.36px;
    }
  }

  @include mq.mq($from: medium) {
    &__title {
      padding-right: 6.5rem;
    }

    &__description {
      padding-right: 6.5rem;
    }

    &__address {
      margin-top: 8rem;
    }

  }
}