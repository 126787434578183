@use "../mixins/mq";

.seminar-teaser {
  --link-color: var(--color-black);

  display: flex;
  justify-content: space-between;
  border-top: 0.1rem solid var(--color-grey-400);
  padding: 3rem 0;
  margin-right: var(--wrapper-gap-negative);
  gap: 1.5rem;


  &:last-child {
    border-bottom: 0.1rem solid var(--color-grey-400);
  }

  &__content {
    transition: transform var(--animation-duration) var(--animation-ease);

    .h2 {
      --spacing-h2-bottom: 1rem;
    }

    &-description {
      font-weight: 400;
      font-size: 1.7rem;
      letter-spacing: 0.34px;
    }

  }


  &__image {
    position: relative;
    overflow: hidden;
    aspect-ratio: 0.66197;
    min-width: 9.4rem;
    margin-bottom: auto;

    figure {
      position: absolute;
      top: 0;
      height: 100%;
      left: -1rem;
      width: calc(100% + 1rem);
      transition: transform var(--animation-duration) var(--animation-ease);

      picture {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }

      }

    }
  }

  .action-tertiary {
    gap: 0;
  }

  &:hover {
    .seminar-teaser__content {
      transform: translateX(1.5rem);
    }

    .seminar-teaser__image {
      figure {
        transform: translateX(1rem);

      }
    }
  }


  @include mq.mq($from: medium) {
    &__image {
      aspect-ratio: 0.74648;
      min-width: 10.6rem;
    }

  }


}