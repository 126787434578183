@use "../mixins/mq";

.article-teaser {
  &__container {
    margin-right: var(--wrapper-gap-negative);
  }

  &__item {
    --action-icon-path-transform: translateX(-1rem);

    display: block;
    text-decoration: none;
    cursor: pointer;

    &:hover, &:focus {
      --action-hover-icon-path-transform: translateX(0rem);
      --action-icon-path-transform: var(--action-hover-icon-path-transform, none);
      --link-color: var(--color-secondary-900);

      .article-teaser__image {
        figure {
          transform: translateX(3rem);
        }
      }

      .article-teaser__content--line .article-teaser__icon {
        transform: translateX(0);

      }
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    aspect-ratio: 0.67167;

    figure {
      position: absolute;
      left: -3rem;
      top: 0;
      height: 100%;
      width: calc(100% + 3rem);
      transition: transform var(--animation-duration) var(--animation-ease);

      picture {
        height: 100%;
        width: 100%;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }

  }

  &__content {
    &--line {
      display: flex;
      justify-content: space-between;

      .article-teaser__icon {
        transform: translateX(1rem);
        transition: all var(--animation-duration) var(--animation-ease);
        margin-top: 3rem;
      }
    }
  }

  &__linktext {
    margin-top: 2.5rem;
    flex: 1;
  }

  &__text {
    margin-top: 1rem;
    color: var(--color-black);
  }

  &__icon {
    margin-top: 2.5rem;
    color: var(--color-secondary-900);
  }

  @include mq.mq($from: medium) {
    &__container {
      margin-right: 0;
    }

    &__image {
      aspect-ratio: 0.66462;
    }
  }
}