.tabs {
    display: flex;
    border-bottom: .1rem solid currentColor;
    align-items: flex-end;
    gap: .5rem;

    &__link {
        color: currentColor;
        border-radius: .5rem .5rem 0 0;
        border: .1rem solid currentColor;
        padding: .5rem 1rem;
        font-size: 1.6rem;
        line-height: 1.2;
        border-bottom: 0;
        display: block;

        --link-text-decoration-active: none;
      
        .fui-tab-active &,
        &--active,
        &:hover {
            background-color: rgba(127,127,127,.3);
            text-decoration: none;
        }

        .fui-tab-error &,
        &--error {
            background-color: var(--color-error);
        }
    }
}

