@use "../mixins/mq";

.quick-search {
    --field-margin-bottom: 0;
    --input-background-color: transparent;

    &__submit {
        display: none;
    }

    @include mq.mq($from: large) {
        &__submit {
            display: block;
        }
    
    }
}