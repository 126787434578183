@use "../mixins/mq";

.form {
      &__actions {
        display: flex;
        gap: 1.6rem;
        margin-top: 2rem;
        justify-content: space-between;
    }

    &__row {
        display: flex;
        flex-direction: column;
    }

    @include mq.mq($from: medium) {
        &__row {
            gap: var(--form-row-gap,0);
            flex-direction: row;
        }
    }
}