@use "../mixins/mq";

.config {
    --config-color: #9442FF;
    --config-color-dark: #0068B4;

    border-top: var(--config-color) 2rem solid;
    margin: 8rem 0;


    &--section-explainer {
        .section {
            --bg-color: #C9A0FF;

            &--spacing-small {
                --bg-color: #41E3DA;
            }

            &--spacing-large {
                --bg-color: #F39100;
            }

            position: relative;

            &:after,
            &:before {
                content: "";
                aspect-ratio: 1 / 1;
                width: var(--section-spacing);
                background-color: var(--bg-color);
                position: absolute;
                display: block;
                bottom: 100%;
                opacity: .5;
                left: 50%;
            }

            &:after {
                top: 100%;
                left: unset;
                right: 50%;
            }

            &--inner-spacing {
                &:after {
                    top: auto;
                    bottom: 0;
                }

                &:before {
                    bottom: auto;
                    top: 0;
                }
            }
        }


    }

    .inverted {
        --config-color: var(--color-white);

        color: var(--color-white);
    }

    &__header {
        margin: 8rem 0;
        text-transform: uppercase;
        color: var(--config-color);
        font-weight: 500;
    }

    &__container {
        padding-inline: var(--wrapper-gap);
    }

    &__legend {
        font-weight: bold;
        border-bottom: .1rem solid currentColor;
        margin: 6rem 0;
    }

    &__grid {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 2rem 4rem;
        align-items: center;

        &--half {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 3rem;
    }

    &__code {
        font-size: 1.4rem;
        color: var(--config-color);
        display: block;

        &--small {
            font-size: 1.2rem;
        }
    }

    &__color {
        background: var(--config-color-bg);
        aspect-ratio: 1/1;
        display: block;
        width: 9rem;
        border-radius: 50%;
        margin: 0 auto 2rem;
        box-shadow: 0 0 0.1rem #888888;
    }

    &__box {
        aspect-ratio: 1/1;
        display: block;
        width: 22rem;
        background-color: var(--color-white);
        box-shadow: var(--config-box-shadow);
        border-radius: 1rem;
        margin: 0 auto 2rem;
    }

    &__bodytext {
        max-width: 66.6rem;
        margin: 0 auto;
    }

    &__section {
        margin: 6rem 0;

        &--backdrop {
            padding: .1rem 0 5rem;
            box-shadow: -20rem 0 0 0 var(--color-grey-100), 20rem 0 0 0 var(--color-grey-100);
            background-color: var(--color-grey-100);
        }

        &--dark {
            padding: .1rem 0 5rem;
            box-shadow: -20rem 0 0 0 var(--color-primary-900), 20rem 0 0 0 var(--color-primary-900);
            background-color: var(--color-primary-900);
        }
    }


    &__contrast-box {
        background-color: var(--color-grey-100);
        padding: 4rem;

        &--transparent {
            background-color: transparent;
            
        }

        &--negative {
            background-color: var(--color-primary-900);
            color: var(--color-white);
        }
    }




    @include mq.mq($from: medium) {
        &__grid {
            gap: 4rem 8rem;
        }

        &__code {
            display: block;
            font-size: inherit;
            color: var(--config-color);

            &--small {
                font-size: 1.4rem;
            }
        }
    }

}