@use "../mixins/mq";

.logos {
	&__list {
		display: flex;
		place-content: center;
	}

	&__logo {
		width: 100%;
		display: flex;
		justify-content: center;
		gap: 4rem;
		padding-left: 0;

		@include mq.mq($from: medium) {
			width: calc(100% / 3);
		}

		@include mq.mq($from: large) {
			width: calc(100% / 5);
		}
	}

	&__figure {
		width: 100%;
		text-align: center;
	}

	&__svg {
		width: 100%;
		height: auto;
		max-height: 5rem;
	}
}
