.progress {
    display: block;
    background-color: var(--color-grey-100);
    list-style: 1;
    white-space: nowrap;

    &__bar {
        max-width: 100%;
        min-height: 4rem;
        background-color: var(--color-primary-900);
        color: var(--color-white);
        display: grid;
        align-items: center;
        justify-content: center;
    }
}