@use "../_config/colors";
@use "../mixins/mq";

// font weight
$font-weight-mapping: (
  100: 'thin',
  200: 'extra-light',
  300: 'light',
  400: 'normal',
  500: 'medium',
  600: 'semi-bold',
  700: 'bold',
  800: 'extra-bold',
  900: 'black',
);

@each $weight, $name in $font-weight-mapping {
  .font-weight-#{$weight},
  .font-weight-#{$name} {
    font-weight: $weight;
  }
}


.font-family-primary {
  font-family: var(--font-family-primary);
}

.font-family-secondary {
  font-family: var(--font-family-secondary);
}

// Header
$sizes: h1, h2, h3, h4, h5, h6;

@each $size in $sizes {

  #{$size},
  %#{$size},
  .#{$size} {
    --spacing-top: var(--spacing-#{$size}-top, 0);
    --spacing-bottom: var(--spacing-#{$size}-bottom, 0);

    // font-weight: var(--font-weight-#{$size});
    color: var(--color-#{$size}, currentColor);
    display: block;
    font-family: var(--heading-font-family);
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    font-weight: var(--heading-font-weight);
    letter-spacing: var(--letter-spacing-#{$size});
    margin-top: var(--spacing-top);
    margin-bottom: var(--spacing-bottom);
    text-wrap: var(--heading-text-wrap);

    .no-margin & {
      --spacing-top: 0;
      --spacing-bottom: 0;
    }

    &:first-child {
      --spacing-top: 0;
    }

    &:last-child {
      --spacing-bottom: 0;
    }

  }

  .font-size-#{$size} {
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    letter-spacing: var(--letter-spacing-#{$size});
  }
}


strong,
b {
  font-weight: bold;

  // letter-spacing: var(--bold-letter-spacing);
}


.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}


.lead-text {
  font-family: var(--lead-text-font-family);
  font-size: var(--lead-text-font-size);
  line-height: var(--lead-text-line-height);
  letter-spacing: var(--lead-text-letter-spacing);
}


small,
.subtitle,
.small {
  --spacing-text-top: .5rem;
  --spacing-text-bottom: .5rem;

  font-family: inherit;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  letter-spacing: var(--small-letter-spacing);
}

.subtitle {
  font-family: inherit;
  font-size: var(--subtitle-font-size);
  line-height: var(--subtitle-line-height);
  letter-spacing: var(--subtitle-letter-spacing);
}

.subtitle-small {
  font-family: inherit;
  font-size: var(--subtitle-small-font-size);
  line-height: var(--subtitle-small-line-height);
  letter-spacing: var(--subtitle-small-letter-spacing);
}

.media {
  display: block;
  container-type: inline-size;
  margin-top: var(--spacing-media-top);
  margin-bottom: var(--spacing-media-bottom);

  &:first-child {
    --spacing-media-top: 0;
  }

  &:last-child {
    --spacing-media-bottom: 0;
  }

  &__caption {
    margin-top: var(--spacing-media-caption);
    font-size: var(--subtismalltle-font-size);
    line-height: var(--small-line-height);
    letter-spacing: var(--small-letter-spacing);
    color: var(--color-grey-500);
    font-weight: 400;
    white-space: normal;
  }
}

.text-transparent {
  opacity: .4;
}

.uppercase {
  text-transform: uppercase;
}

.fit-content {
  min-width: fit-content;
}


blockquote {
  display: inline-block;
  quotes: "«" "»";
  font-size: var(--lead-text-font-size);
  line-height: var(--lead-text-line-height);
  letter-spacing: var(--lead-text-letter-spacing);
  font-weight: var(--lead-text-font-weight);
  color: var(--color-primary-900);
  margin-top: var(--spacing-blockquote-top);
  margin-bottom: var(--spacing-blockquote-bottom);

  &:first-child {
    --spacing-blockquote-top: 0;
  }

  &:last-child {
    --spacing-blockquote-bottom: 0;
  }

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }

  :where(*) {
    display: inline;
  }
}

sup {
  vertical-align: super;
  font-size: smaller;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

.link,
button,
a {
  font-weight: var(--link-font-weight, inherit);
  color: var(--link-color, inherit);
  text-decoration: var(--link-text-decoration, underline);
  transition: color var(--animation-duration) var(--animation-ease);
  will-change: color;
  hyphens: manual;

  &:focus,
  &.focus,
  &.hover,
  &:hover {
    --link-text-decoration: var(--link-text-decoration-hover, underline);
    --link-color: var(--link-color-hover);
  }
}


p {
  margin-top: var(--spacing-text-top);
  margin-bottom: var(--spacing-text-bottom);

  &:has(.action-primary),
  &:has(.action-secondary),
  &:has(.action-quaternary) {
    --spacing-text-top: var(--spacing-action-top);
    --spacing-text-bottom: var(--spacing-action-bottom);
  }

  &:first-child {
    --spacing-text-top: 0;
  }

  &:last-child {
    --spacing-text-bottom: 0;
  }

}

::marker {
  color: var(--marker-color, currentColor);
  transition: color var(--animation-duration) var(--animation-ease);
  font-weight: inherit;
  line-height: 1;
}

ul {
  list-style-type: var(--list-style-type, disc);
}

ul,
ol {
  margin-top: var(--spacing-list-top, 0);
  margin-bottom: var(--spacing-list-bottom, 0);
  margin-left: var(--list-margin-left, 0);

  &:first-child {
    --spacing-list: 0;
    --spacing-text-top: 0;
    --spacing-list-top: .2rem;
    --spacing-list-bottom: .2rem;
  }

  &:last-child {
    --spacing-list-bottom: 0;
  }
}


ol {
  --list-margin-left: var(--ordered-list-margin-left);
  --list-item-padding-left: var(--ordered-list-item-padding-left, 0);
  --list-item-margin-top: var(--ordered-list-item-margin-top, 0);
}

.unstyled-list {
  --list-item-padding-left: initial;
  --list-margin-left: initial;
  --list-item-margin-top: var(--unstyled-list-item-margin-top);
  
  list-style: none;
}

li {
  min-height: var(--list-item-min-height);
  position: var(--list-item-position, static);
  padding-left: var(--list-item-padding-left, 0);
  margin-top: var(--list-item-margin-top, 0);
  counter-increment: list-counter;
  border-style: var(--list-item-border-style);
  border-width: var(--list-item-border-width, 0);
  border-color: var(--list-item-border-color, currentColor);
}

.check-list {
  --list-margin-left: 3rem;
  --list-item-padding-left: 0;
  --list-item-margin-top: 0.9rem;
  --list-item-position: relative;

  // list-style-image: var(--check-list);
  list-style: none;

   > li:before {
    content: "";
    position: absolute;
    display: block;
    background-image: var(--check-list);
    background-position: center;
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    top: 0.2rem;
    left: -3rem;
    width: 2rem;
    aspect-ratio: 1/1;
  }

}

.reset-list {
  --spacing-list: initial;
  --spacing-list-top: none;
  --list-item-padding-left: initial;
  --list-margin-left: initial;
  --list-item-margin-top: initial;

  list-style: none;
}

.font-color-primary {
  color: var(--color-primary-900);
}


