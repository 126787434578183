@use "../mixins/mq";

.text-image__item {
	--spacing-h2: 0;

	&:not(:first-child) {
		margin-top: 6rem;

		@include mq.mq($from: large) {
			margin-top: 10rem;
		}
	}


}

.text-image__item-text {
	padding-top: 3rem;

	@include mq.mq($from: large) {
		padding-bottom: 3rem;

		.row--reverse & {
			padding-left: 0;
		}
	}
}
