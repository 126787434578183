@use "../mixins/mq";

.section {
	--section-spacing-block: var(--section-spacing);
	--section-spacing-top: var(--section-spacing-block);
	--section-spacing-bottom: var(--section-spacing-block);

	position: relative;
	margin-top: var(--section-spacing-top);
	margin-bottom: var(--section-spacing-bottom);
	background-color: var(--section-bg-color,none);
	padding-block: var(--section-inner-spacing, 0);

	&--spacing-small {
		--section-spacing: var(--section-spacing-s);
	}

	&--spacing-medium {
		--section-spacing: var(--section-spacing-m);
	}

	&--no-spacing {
		--section-spacing-block: 0;
	}

	&--inner-spacing {
		--section-inner-spacing: var(--section-spacing); 
	}

	&--dark {
		--section-bg-color: var(--color-grey-500);
	}

}