@use "../mixins/mq";

.breadcrumb {
	--icon-width: 2.5rem;
	--icon-height: 2.5rem;
	--outline-offset: .25rem;
	--link-color-active: var(--color-primary-900);

	max-width: 100%;
	font-size: var(--subtitle-small-font-size);
	line-height: var(--subtitle-small-line-height);
	letter-spacing: var(--subtitle-small-letter-spacing);
	font-weight: 400;
	white-space: nowrap;
	overflow: auto hidden;
	padding: .5rem;
    scroll-behavior: smooth;
	margin: 0 -.5rem;
	color: var(--color-grey-300);


	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		display: inline-flex;
		align-items: center;
		gap: .2rem
	}

	&__item {
		padding: 0;
		margin: 0;

		&:first-child {
			margin-right: 0.8rem;
		}
	}

	&__link {
		margin: 0;
		text-decoration: none;
		display: inline;

		&--current {
			font-weight: 700;
			color: var(--link-color-active);
		}
	}

	&__icon {
		display: inline-block;
	}

}

