table {
  --table-border-color: var(--color-grey-400);
  --table-td-padding: 1rem;

  border-collapse: collapse;
  border: 0;
  width: 100%;
  margin-bottom: var(--spacing-media);

}

thead,
tr {
  border-top: .1rem solid var(--table-border-color);

  &:last-child {
    border-bottom: .1rem solid var(--table-border-color);
  }
}

th,
td {
  padding: var(--table-td-padding);

}

.table {
  min-height: 0.01%;
  overflow: auto hidden;
  scroll-behavior: var(--scroll-behavior);
  -webkit-overflow-scrolling: touch;
  word-break: normal;
}