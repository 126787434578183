@use "../mixins/mq";

.list {
    border-top: var(--color-grey-400) .1rem solid;
    padding-block: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    color: inherit;
    font-weight: inherit;

 
    &:last-child {
        border-bottom: var(--color-grey-400) .1rem solid;
    }

    &--link {
        &:hover,
        &:focus {
            --list-infos-transform-translate-x: 1.5rem;
        }
    }

    &__infos {
        transform: translateX( var(--list-infos-transform-translate-x, 0) );
        transition: transform var(--animation-duration) var(--animation-ease);
    }

    &__link {
        flex-shrink: 0;
        display: inline-flex;
        color: var(--color-secondary-900);
    }

    .action-tertiary {
        // Stop animation and set small size
        --action-icon-path-transform:  translateX(0);
        --icon-width: 5.4rem;
    }

    @include mq.mq($from: large) {
        gap: 3rem;
        padding-block: 5rem;

        &--inline {
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
        }
    }

}