@use "../mixins/mq";
@use "../_base/typography";
@use "../atoms/action";


.footer__accordion {
  background: var(--color-primary-900);
  color: var(--color-white);

  &-details {
    border-bottom: 0.1rem solid var(--color-primary-800);

    > div {
      padding: 0 var(--wrapper-gap) 2.5rem var(--wrapper-gap);
    }

    &[open] {
      .footer__accordion-summary {
        padding-bottom: 1.5rem;

        .icon {
          transform: rotate(180deg);
        }
      }
    }

    &:last-child {
      border: none;
    }

    > .nav {
      padding: 0 var(--wrapper-gap) 2.5rem var(--wrapper-gap);


      .nav__wrap {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

      }

      .nav__item {
        --list-item-min-height: 0;
        --body-line-height: 150%;
      }

      .nav__link {
        --link-font-weight: 400;
        --link-color: var(--color-primary-800);

      }

    }


  }

  &-summary {
    list-style: none;
    padding: 2.5rem var(--wrapper-gap);
    display: flex;
    justify-content: space-between;


    .icon {
      transition: transform var(--animation-duration) var(--animation-ease);
    }

  }

  @include mq.mq($from: medium) {
    display: none;
  }
}