@use "../mixins/mq";

.teaser-image-text {
    --spacing-h3-top: .5rem;
    --img-transform: translateX(0);

    color: inherit;
    display: grid;
    font-weight: inherit;
    gap: 2.5rem var(--grid-gap);

    &:hover,
    &:focus {
        --img-transform: translateX(3rem);        
    }

    &__image {
        width: calc(100% + 3rem);
        max-width: none;
        margin-left: -3rem;
        height: 100%;
    }

    &__media-mask {
        overflow: hidden;
        margin-right: var(--wrapper-gap-negative);
    }

 

    @include mq.mq($from: medium) {
        grid-template-columns: repeat(10, 1fr);
        grid-template-areas: "media media media media media gap content content content content";
        align-items: center;

        &--reserved {
            grid-template-areas: "content content content content gap media media media media media";
        }

        &__content {
            grid-area: content;
        }

        &__media-mask {
            grid-area: media;
            margin: 0 0 auto;
        }
    }
}