@use "../mixins/mq";

.text {
  &__quote {
    margin-top: 7.5rem;

    p {
      margin: 0;
    }

    blockquote {
      margin-top: 1rem;
    }

    &--noauthor {
      blockquote {
        margin-top: 0;
      }
    }
  }

  @include mq.mq($from: medium) {
    .hero--has-cover + .cmp-text-section & {
      --text-quote-margin-top: 50rem;
    }

    &__quote {
      margin-top: var(--text-quote-margin-top,5rem);
    }

  }

}