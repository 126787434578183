@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";


[data-fui-page-hidden],
[data-conditionally-hidden] {
    display: none;
}

// Group for label and input
.field {
    flex: 1;
    margin-bottom: var(--field-margin-bottom);
    color: var(--field-color, currentColor);
    font-size: var(--label-font-size);

    &:has( .input:user-valid ){
        --label-color: var(--color-grey-500);
    }

    &:has( .input:user-invalid ){
        --label-color: currentColor;
    }

    /*
    &:has( .input:not(:placeholder-shown):user-valid) {
        --label-color: var(--color-grey-400);
    }
*/

    &--group {
        margin-bottom: 0;
    }



    &--no-spacing {
       margin-bottom: 0;
    }

    
    &--section {
        margin: 0;
    }

    &__errors,
    &__error {
        list-style: none;
        font-size: var(--field-error-font-size);
        line-height: var(--field-error-line-height);
        letter-spacing: var(--field-error-letter-spacing);
        color: var(--field-error-color, currentColor);
        margin: var(--field-error-margin);
        font-weight: 500;
    }

    &__option-group {
        display: grid;
        gap: 1.5rem;

        &--horizontal {
            grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
        }

    }

    &__option,
    &__group {
        position: relative;

        &--inline {
            display: flex;
            align-items: center;
        }
    }

    &__instructions {
        margin: var(--field-instructions-margin);
        color: var(--color-grey-500);
        font-size: var(--field-instructions-font-size, 1.4rem);
        line-height: var(--field-instructions-line-height, 1.5rem);
        font-weight: var(--field-instructions-font-weight, 500);
    }

    &--hidden {
        display: none;
    }

    &__append {
        &--start {
            order: -1;
        }
    }

    &__reset {
        visibility: var(--field-reset-visibility, visible);

        .input--search:placeholder-shown~& {
            --field-reset-visibility: hidden;
        }


    }

}


.input {
    // Resets
    font-family: inherit;
    font-size: var(--input-font-size);
    line-height: var(--input-line-height);
    margin: 0;
    appearance: none;
    color: var(--input-color, currentColor);
    width: 100%;
    height: var(--input-height);
    display: block;
    padding-inline: var(--input-padding-inline);
    padding-block: var(--input-padding-block);
    caret-color: var(--input-caret-color, auto);
    border: 1px solid var(--input-border-color);
    border-width: var(--input-border-width, 1rem);
    border-radius: var(--input-border-radius, 0);
    background-color: var(--input-background-color);
    background-clip: padding-box;
    transition: var(--input-transition);
    text-align: var(--input-text-align, inherit);
    outline: none;

    &:focus-visible {
        --input-border-color: var(--input-focus-border-color);
    }


    &::placeholder {
        color: var(--input-placeholder-color, currentColor);
    }

    &[disabled] {
        --input-color: var(--color-grey-400);
        --input-border-color: var(--color-grey-400);
        --input-background-color: var(--color-grey-100);
        --input-file-button-bg-color: var(--color-disabled);
    }


    &--file {
        --input-file-button-bg-color: var(--color-primary-900);
        --input-padding-block: .6rem;
        --input-padding-inline: .6rem 1.5rem;

        overflow: hidden;
        cursor: pointer;

        &::file-selector-button {
            color: var(--color-white);
            margin-right: 1.8rem;
            isolation: isolate;
            border: none;
            appearance: none;
            box-sizing: content-box;
            text-align: center;
            font-size: var(--input-font-size);
            background-color: var(--input-file-button-bg-color);
            padding: .85rem 2rem;
            border-radius: .4rem;
            transition: background-color var(--animation-duration) var(--animation-ease), box-shadow var(--animation-duration) var(--animation-ease);

            &:hover {
                --input-file-button-bg-color: var(--color-primary-800);
            }

            &:active {
                --input-file-button-bg-color: var(--color-accent-01);
            }

        }

        &::-webkit-textfield-decoration-container {
            display: none;
        }
    }

    &--center {
        text-align: center;
    }


    /* don't hide it, needed for screen reader */

    &--radio,
    &--checkbox {
        --input-border-radius: .1rem;

        margin: unset;
        width: var(--check-size);
        height: var(--check-size);
        border: transparent var(--check-border-width) solid;
        padding: 0;
        background: none;
        position: absolute;
    }

    &--switch {
        --input-border-radius: .1rem;

        margin: unset;
        width: var(--switch-width);
        height: var(--switch-height);
        border-radius: var(--switch-border-radius);
        border: transparent var(--switch-border-width) solid;
        padding: 0;
        background: none;
        position: absolute;
    }

    &--radio {
        border-radius: 50%;
    }

    &--textarea {
        height: auto;
        min-height: unset;
        resize: vertical;
    }

    &--dropdown,
    &--select {
        // icon

        &:not([multiple], [size]) {
            padding-right: var(--input-select-padding-right);
            background-repeat: no-repeat;
            background-size: 1rem 1rem;
            background-image: var(--input-select-arrow-bg);
        }

        &:invalid {
            color: var(--input-placeholder-color, currentColor);
        }

        &[size],
        &[multiple] {
            height: auto;
        }

    }

    &--input-aside-icon,
    &--date-picker,
    &--time {
        padding-right: var(--input-aside-icon-padding-right);
        background-position: var(--input-aside-icon-arrow-bg-position);
        background-repeat: no-repeat;
        background-size: var(--input-aside-icon-bg-bg-size);
        background-image: var(--input-aside-icon-bg-image, none);
    }

    &--date-picker {
        --input-aside-icon-bg-image: var(--input-aside-date-picker);
    }

    &--time {
        --input-aside-icon-bg-image: var(--input-aside-time-picker);
    }

    &::-webkit-calendar-picker-indicator {
        background-size: contain;
        background-image: var(--input-aside-time-picker);
    }



}


.label {
    font-size: var(--label-font-size);
    line-height: var(--label-line-height);
    letter-spacing: var(--label-letter-spacing);
    color: var(--label-color, currentColor);
    padding: var(--label-padding);
    font-weight: 500;
    display: inline-block;
    transition: color var(--animation-duration) var(--animation-ease);

    &--legend {
        margin-block: 3rem 1.5rem;
        padding: var(--label-legend-legend-padding);
        font-size: var(--label-legend-font-size);
        line-height: var(--label-legend-line-height);
        letter-spacing: var(--label-legend-letter-spacing);
        font-weight: var(--label-legend-font-weight);
        font-family: var(--label-legend-family);
    }

    /* default option */
    &--option {
        display: block;
        color: var(--label-option-color, inherit);
        padding: .3rem 0 0 calc(var(--check-size) + 1.5rem);
        font-size: var(--label-option-font-size, inherit);
        min-height: var(--check-size);
        line-height: inherit;
        user-select: none;
        cursor: pointer;

        :checked+& {
            --check-color: var(--check-checked-color);
            --check-bg-color: var(--check-checked-bg-color);

            // add the bg image
            --check-bg-image: var(--check-checked-bg-image);
            --check-radio-bg-image: var(--check-checked-radio-bg-image);
        }

        &:hover {
            --check-color: var(--check-hover-color);
        }

        [disabled]+&,
        :disabled+& {
            --check-color: var(--color-disabled);
            --label-option-color: var(--color-disabled);

            cursor: auto;
        }

        &:before {
            content: '';
            cursor: pointer;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: var(--check-size);
            height: var(--check-size);
            border: var(--check-color) var(--check-border-width) solid;
            color: var(--check-color);
            background-color: var(--check-bg-color, currentColor);
            background-image: var(--check-bg-image, none);
            border-radius: var(--check-border-radius, 0);
            transition: var(--check-transition);
            background-size: var(--check-bg-size, 100% 100%);
            background-repeat: no-repeat;
            background-position: center center;

            [type="radio"]+& {
                --check-border-radius: 50%;

                background-image: var(--check-radio-bg-image, none);
            }


        }


    }

    &--switch {
        display: block;
        padding: .1rem 0 0 calc(var(--switch-width) + 1.5rem);
        font-size: var(--label-option-font-size, inherit);
        min-height: var(--switch-height);
        line-height: inherit;
        user-select: none;
        cursor: pointer;

        :checked+& {
            --switch-bg-color: var(--switch-checked-bg-color);
            --switch-transform: translateX(100%);
        }

        &:hover {
            --switch-bg-color: var(--switch-hover-bg-color);
        }

        [disabled]+&,
        :disabled+& {
            --switch-bg-color: var(--color-grey-400);
            --switch-track-bg-color: var(--color-grey-200);

            cursor: auto;
        }

        &:after {
            content: '';
            cursor: pointer;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: var(--switch-border-radius);
            width: var(--switch-width);
            height: var(--switch-height);
            background-color: var(--switch-track-bg-color);
            transform: scale(.8);
            box-shadow: inset 0 0 1rem rgba(0, 0, 0, 0.20);
        }

        &:before {
            content: '';
            cursor: pointer;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: var(--switch-thumb-size);
            height: var(--switch-thumb-size);
            border-radius: var(--switch-border-radius);
            background-color: var(--switch-bg-color, currentColor);
            transition: background-color var(--animation-duration) var(--animation-ease), transform var(--animation-duration) var(--animation-ease);
            transform: var(--switch-transform, none);
        }


    }


}



/* error */
[aria-describedby^="error"],
.error,
.fui-error {
    // --label-color: var(--color-error);
    // --input-background-color: var(--color-error-transparent);

    --input-color: var(--color-error);
    --input-select-arrow-bg: var(--input-select-arrow-error);
    --input-border-color: var(--color-error);
    --input-focus-border-color: var(--color-error);
    --input-caret-color: auto;
    --check-color: var(--color-error);
    --switch-track-bg-color: var(--color-error-transparent);
    --switch-bg-color: var(--color-error);
}

/* for formie, make field in flex wider */
[data-field-type="address1"],
[data-field-type="address-city"] {
    flex: 2;
}

