@use "../mixins/mq";

.images {
  img {
    width: 100%;


    @include mq.mq($from: medium) {
      aspect-ratio: 16/9;
    }
  }
}