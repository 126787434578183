@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";
@use "../utils/visually-hidden";
@use "../mixins/mq";

/* base class */

.action {
	--icon-width: 2.2rem;

	position: relative;
	display: inline-flex;
	gap:  var(--action-gap, 1.5rem);
	justify-content: var(--action-justify-content, center);
	align-items: center;
	max-width: 100%;
	overflow: visible;
	text-decoration: none;
	text-align: var(--action-text-align, center);
	color: var(--action-color);
	border-color: var(--action-border-color, currentColor);
	border-width: var(--action-border-width, .1rem);
	border-style: var(--action-border-style, none);
	border-radius: var(--action-border-radius,0);
	background-color: var(--action-bg-color, none);
	padding: var(--action-padding, 0);
	min-width: var(--action-min-width, none);
	width: var(--action-width, auto);
	min-height: var(--action-min-height, none);
	height: var(--action-height, auto);
	font-size: var(--action-font-size, 1.8rem);
	line-height: var(--action-line-height, 1.5);
	letter-spacing: var(--action-letter-spacing, inherit);
	font-weight: var(--action-font-weight, 700);
	font-family: var(--action-font-family, inherit);
	z-index: 1;
	flex-shrink: 0;
	text-wrap: balance;
	margin-left: var(--action-margin-left, 0);
	margin-right: var(--action-margin-right, 0);
	transition: background-color var(--animation-duration) var(--animation-ease), color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
	cursor: pointer;

	&__text {
		vertical-align: middle;
		color: var(--action-text-color, currentColor);
	}

	&__icon-path {
		transform: var(--action-icon-path-transform, none);
		transition: transform var(--animation-duration) var(--animation-ease);
	}

	// disabled
	&[disabled],
	&.disabled,
	&:disabled {
		--action-color: var(--action-disabled-color);
		--action-border-color: var(--action-disabled-border-color, none);
		--action-bg-color: var(--action-disabled-bg-color, none);
	}

	// hover
	&:hover,
	&.hover,
	&:focus,
	a:focus &,
	a:hover & {
		--action-color: var(--action-hover-color);
		--action-border-color: var(--action-hover-border-color, none);
		--action-bg-color: var(--action-hover-bg-color, none);
		--action-icon-path-transform: var(--action-hover-icon-path-transform, none);
	}

	// press
	&.active,
	&:active,
	a.active &,
	a:active & {
		--action-color: var(--action-active-color);
		--action-border-color: var(--action-active-border-color, none);
		--action-bg-color: var(--action-active-bg-color, none);
		--action-icon-path-transform: var(--action-active-icon-path-transform, none);
	}


	// selected
	&.selected {
		--action-color: var(--action-selected-color);
		--action-bg-color: var(--action-selected-bg-color, none);
	}


}

/* sizes for action buttons */
.size-m {
	--button-padding: var(--button-m-padding);
	--button-font-size: var(--button-m-font-size);
	--button-min-width: var(--button-m-min-width);
	--button-letter-spacing: var(--button-m-letter-spacing);
}

.size-l {
	--button-padding: var(--button-l-padding);
	--button-font-size: var(--button-l-font-size);
	--button-min-width: var(--button-l-min-width);
	--button-letter-spacing: var(--button-l-letter-spacing);
}

.size-xl {
	--button-padding: var(--button-xl-padding);
	--button-font-size: var(--button-xl-font-size);
	--button-min-width: var(--button-xl-min-width);
	--button-letter-spacing: var(--button-xl-letter-spacing);
}


/* 01 Primary Action */

.action-primary {
	--action-color: var(--color-white);
	--action-bg-color: var(--color-secondary-900);
	--action-padding: var(--button-padding);
	--action-letter-spacing: var(--button-letter-spacing);
	--action-min-height: var(--button-min-height);
	--action-min-width: var(--button-min-width);
	--action-font-size: var(--button-font-size);
	--loading-icon: var(--loading-icon-white);

	// hover
	--action-hover-color: var(--color-white);
	--action-hover-bg-color: var(--color-black);

	// active
	--action-active-color: var(--color-white);
	--action-active-bg-color: var(--color-secondary-900);

	// disabled
	--action-disabled-color: var(--color-grey-500);
	--action-disabled-bg-color: var(--color-disabled);

}

/* 02 Secondary Action inverted */

.action-secondary {
	--action-color: var(--color-primary-800);
	--action-border-style: solid;
	--action-padding: var(--button-padding);
	--action-letter-spacing: var(--button-letter-spacing);
	--action-min-height: var(--button-min-height);
	--action-min-width: var(--button-min-width);
	--action-font-size: var(--button-font-size);

	// hover
	--action-hover-color: var(--color-black);

	// active
	--action-active-color: var(--color-primary-800);

	// disabled
	--action-disabled-color: var(--color-disabled);

	:where(.inverted) &,
	&--inverted {
		--action-color: var(--color-grey-400);
		--action-hover-color: var(--color-white);
		--action-active-color: var(--color-white);
		--loading-icon: var(--loading-icon-white);
		--action-disabled-color: var(--color-disabled);

	}

}

/* 03 Tertiary Action */

.action-tertiary {
	--icon-width: 6.4rem;
	--icon-height: 1.6rem;
	--action-color: var(--color-secondary-900);
	--action-active-color: var(--color-secondary-900);
	--action-hover-color: var(--color-secondary-900);
	--action-disabled-color: var(--color-disabled);
	--action-icon-path-transform:  translateX(-1rem);
	--action-hover-icon-path-transform: translateX(0rem);
	--action-active-icon-path-transform:  translateX(-1rem);
	--action-text-align: left;



	:where(.inverted) &,
	&--inverted {
		--action-color: var(--color-white);
		--action-active-color: var(--color-white);
		--action-hover-color: var(--color-grey-200);
		--action-disabled-color: var(--color-disabled);
		--loading-icon: var(--loading-icon-white);
	}

	:where(.current-color) & {
		--action-color: currentColor;
		--action-active-color: currentColor;
		--action-hover-color: currentColor;
		--action-disabled-color: var(--color-grey-300);
	}
}

/* 04 Quaternary Action */
.action-quaternary {
	--action-color: var(--color-secondary-900);
	--action-border-style: solid;
	--action-border-width: .2rem;
	--action-padding: var(--button-padding);

	// hover
	--action-hover-color: var(--color-black);

	// active
	--action-active-color: var(--color-secondary-900);

	// disabled
	--action-disabled-color: var(--color-disabled);

}


/* 05 Fab Action  */
// Floating Action Button
// make anny link a fab link, need icon
.action--fab {
	--action-padding: 0;
	
	.action__text {
		@extend %visually-hidden;
	}
}

.action--round {
	--icon-width: 1.6rem;
	--action-padding: 0;
	--action-border-radius: 50%;
	--action-min-width: 5rem;
	--action-min-height: 5rem;

	.action__text {
		@extend %visually-hidden;
	}
}




.action-label {
	--action-font-weight: 700;
	--action-font-size: 1.4rem;
	--action-color: var(--color-white);
	--action-bg-color: var(--color-grey-500);
	--action-border-radius: 1rem;
	--action-padding: .4rem 1.2rem;

	// hover
	--action-hover-color: var(--color-white);
	--action-hover-bg-color: var(--color-grey-400);

	// active
	--action-active-color: var(--color-white);
	--action-active-bg-color: var(--color-grey-500);

	// disabled
	--action-disabled-color: var(--color-white);
	--action-disabled-bg-color: var(--color-disabled);
}

.action-filter {
	--action-font-weight: 700;
	--action-font-size: var(--body-font-size);
	--action-color: var(--color-white);
	--action-bg-color: var(--color-grey-500);
	--action-border-radius: 1rem;
	--action-padding: 1rem 2rem;

	// hover
	--action-hover-color: var(--color-white);
	--action-hover-bg-color: var(--color-grey-400);

	// active
	--action-active-color: var(--color-white);
	--action-active-bg-color: var(--color-grey-500);

	// disabled
	--action-disabled-color: var(--color-white);
	--action-disabled-bg-color: var(--color-disabled);

	// selected
	--action-selected-color: var(--color-white);
	--action-selected-bg-color: var(--color-primary-900);
}

.action-download,
.action-link {
	--icon-width: 1.6rem;
	--action-color: var(--color-secondary-900);
	--action-active-color: var(--color-secondary-900);
	--action-hover-color: inherit;
	--action-disabled-color: var(--color-disabled);
	--action-gap: 1rem;
}

.action-download {
	--icon-width: 1.8rem;
	--action-font-size: 1.8rem;
}

.action-download--large {
	--icon-width: 2.2rem;
	--action-font-size: 2.2rem;
}


.action--block {
	width: 100%;
}

@include mq.mq($until: medium) {
	.action--block-small {
		width: 100%;
	}
}

.action-underline {
	text-decoration: underline;
}



