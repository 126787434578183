@use "../mixins/mq";

.ready {
    --hero-content-translate-x: 0;
    --hero-cover-clip-path: inset(0 0 0 0);
    --hero-cover-opacity: 1;
    --hero-cover-object-view-box: inset(0% 0% 0% 0%);

}

.hero {
    --img-object-view-box: var(--hero-cover-object-view-box, inset(0% 5% 0% 0%));
    --img-aspect-ratio: 8.5/7;
    --hero-text-inlne-offset: var(--wrapper-gap);

    color: var(--color-white);
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: var(--section-spacing-m);

    &__cover {
        clip-path: var(--hero-cover-clip-path, inset(0 0 0 100%));
        opacity: var(--hero-cover-opacity, 0);
        transition: transform var(--animation-duration) var(--animation-ease), opacity var(--animation-duration) var(--animation-ease), clip-path var(--animation-duration) var(--animation-ease);

    }

    &__image {
        margin-left: var(--hero-text-inlne-offset);
        width: 100%;
    }

    &__text {
        transform: translateX(var(--hero-content-translate-x, -100%));
        transition: transform var(--animation-duration) var(--animation-ease);
        background-color: var(--color-primary-900);

    }

    &__heading {
        margin: 5rem var(--hero-text-inlne-offset);
    }

    &__cta {
        margin-top: auto;
        padding: 2.5rem var(--hero-text-inlne-offset);
        background-color: var(--color-white-100-transparent);

        &--search-box {
            width: 100%;
            margin-top: 5rem;
        }
    }

    &__body {
        margin: 2.5rem var(--hero-text-inlne-offset);
    }

    &__pricing {
        display: inline-flex;
        gap: 1rem 2.5rem;
        flex-wrap: wrap;
    }


    @include mq.mq($from: medium) {
        --hero-text-inlne-offset: 0;
        --hero-cover-overlapping: calc( (var( --section-spacing) + 5.2rem) * -1 );


        &:has( + .cmp-text-section) {
            --hero-cover-overlapping: -60rem;
        }

        display: grid;
        align-items: start;
        grid-template-columns: 8fr 4fr;
        grid-template-areas: "text cover";
        gap: 12.5rem;

        &__heading {
            margin-top: 7.5rem;

            &:only-child {
                margin-bottom: 12.5rem;
            }
        }

        &__image {
            margin: 0;
            height: calc(100vh - 12.5rem - var(--header-height));
            max-height: 78.5rem;
        }

        &__cover {
            grid-area: cover;
            z-index: 1;
            margin-bottom: var(--hero-cover-overlapping);
        }


        &__text {
            position: relative;
            grid-area: text;
            grid-column-end: span 2;
            background-color: transparent;

            &:before {
                content: "";
                background-color: var(--color-primary-900);
                inset: 0 38% 0 0;
                position: absolute;
                z-index: -1;
                width: auto;
            }
      
        }

        &__body {
            margin-bottom: 12.5rem;
        }

        &__infos {
            max-width: 80rem;
            padding-right: 10rem;
        }


        &__cta {
            display: inline-block;
            min-width: 35rem;
            position: relative;
            background-color: transparent;

            &:after {
                content: "";
                display: block;
                position: absolute;
                height: 100%;
                width: 100vw;
                background-color: var(--color-white-100-transparent);
                top: 0;
                right: 0;
                z-index: -1;
            }

            &--search-box {
                margin-top: 12.5rem;

                &:after { 
                    right: -50%;
                }
            }
        }

    }

} 