@use "../mixins/mq";


.staff-person {
  .h4 {
    margin-top: 2.5rem;
    margin-bottom: 0.5rem;
  }

  img {
    width: 100%;

  }

  &__functions {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  &__email {
    margin: 0;
  }

}