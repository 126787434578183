@use "../mixins/mq";
@use "../_base/typography";

.nav--footer-nav {
	display: inline-block;

	.nav {
		&__wrap {
			display: flex;
			flex-direction: row;
			gap: 2.5rem;
		}

		&__item {
			display: inline;

		}

		&__link {
			--link-color: var(--color-grey-500);
			--link-font-weight: 400;

			font-size: 1.4rem;
			line-height: 150%;
			letter-spacing: 0.028rem;

			&:hover {
				color: var(--color-secondary-900);
			}
		}



	}
}

.nav--footer-desktop-nav {
	.nav {
		&__wrap {
			display: flex;
			flex-direction: column;
			gap: 1.5rem;
		}

		&__item {
			--list-item-min-height: 0;
		}

		&__link {
			--link-color: var(--color-grey-500);
			--link-font-weight: 400;

			font-size: 1.4rem;
			line-height: 150%;
			letter-spacing: 0.028rem;

			&:hover {
				color: var(--color-secondary-900);
			}
		}
	}
}