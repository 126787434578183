@use "../mixins/mq";
@use "../_base/typography";
@use "../atoms/action";



.footer {
  &__desktop {
    display: none;
  }

  @include mq.mq($from: medium) {
    &__desktop {
      position: relative;
      display: block;
      background: var(--color-grey-300);
      padding: 7.5rem 0 5rem;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        width: 50%;
        height: 100%;
        background: var(--color-primary-900);
      }
    }

    &__block {
      &-title {
        font-weight: 700;
        margin-bottom: 1rem;
        line-height: 150%;
        letter-spacing: 0.028rem;

        &--inverted {
          color: var(--color-white);
        }

      }

      &--inverted {
        color: var(--color-primary-800);
      }
    }
  }
}
