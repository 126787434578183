@use "../mixins/mq";

.text-two-col {
    display: grid;
    gap: 5rem;

    @include mq.mq($from: medium) {
        grid-template-columns: 1fr 1fr;
    
    }
    
    @include mq.mq($from: large) {
        gap: 7.5rem 16%;
    }
}