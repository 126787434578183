@use "../mixins/mq";

.result-teaser {
    color: inherit;
    font-weight: normal;
    text-decoration: none;
    border: .1rem solid var(--color-grey-400);
    border-width: .1rem 0;
    padding-block: 3rem;
    display: grid;
    gap: 2rem;
    font-size: 1.7rem;
    grid-template-columns: 1fr 9.4rem;
    align-items: center;
    margin-top: -.1rem;

    &:hover,
    &:focus {
        --result-teaser-transform: translateX(1rem);
    }

    &__heading {
        margin:  0 0 1rem;
    }

    &__text {
        color: var(--body-color);
    }

    &__body {
        min-width: 1px;
        transition: transform var(--animation-duration) var(--animation-ease);
        transform: var(--result-teaser-transform, translateX(0));
    }

    &__figure {
        margin-bottom: auto;

    }

    &__image {
        margin-left: -1rem;
        max-width: none;
        transition: transform var(--animation-duration) var(--animation-ease);
        transform: var(--result-teaser-transform, translateX(0));

    }

    @include mq.mq($from: medium) {
        font-size: inherit;
        grid-template-columns: 1fr 10.6rem;
    }


}