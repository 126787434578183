hr {
  background: none;
  margin: var(--spacing-text) auto;
  display: block;
  border: none;
  border-top: 0.1rem solid var(--line-color, currentColor);
  clear: both;
  height: .1rem;
  width: 100%;
}
