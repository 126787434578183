@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";


:root {
  // colors
  --color-black: #{colors.$color-black};
  --color-white: #{colors.$color-white};

  // Grey
  --color-grey-100: #{colors.$color-grey-100};
  --color-grey-200: #{colors.$color-grey-200};
  --color-grey-300: #{colors.$color-grey-300};
  --color-grey-400: #{colors.$color-grey-400};
  --color-grey-500: #{colors.$color-grey-500};

  // Primary
  --color-primary-900: #{colors.$color-primary-900};
  --color-primary-800: #{colors.$color-primary-800};

  // Secondary
  --color-secondary-900: #{colors.$color-secondary-900};


  // Feedback
  --color-error: #{colors.$color-error};
  --color-warning: #{colors.$color-warning};
  --color-success: #{colors.$color-success};
  --color-focus: #{colors.$color-focus};

  // Others
  --color-disabled: var(--color-grey-400);
  --outline-color: var(--color-grey-300);

  // Transparent
  --color-white-100-transparent: #{colors.$color-white-100-transparent};
  --color-white-200-transparent: #{colors.$color-white-200-transparent};
  --color-white-400-transparent: #{colors.$color-white-400-transparent};
  --color-secondary-transparent: #{colors.$color-secondary-transparent};
  --color-black-transparent: #{colors.$color-black-transparent};
  --color-error-transparent: #{colors.$color-error-transparent};


  // font family
  --font-family-primary: effra, roboto, sans-serif;
  --font-family-secondary: serif;

  // body 
  --body-font-family: var(--font-family-primary);
  --body-color: var(--color-black);
  --body-bg-color: var(--color-white);
  --body-font-size: 1.8rem;
  --body-line-height: 1.5;
  --body-font-weight: normal;
  --body-letter-spacing: .02em;
  --scroll-behavior: smooth;
  --hyphens-style: auto;

  // Headings font size
  --font-size-h1: 3.6rem;
  --font-size-h2: 3.2rem;
  --font-size-h3: 2.8rem;
  --font-size-h4: 2.2rem;
  --font-size-h5: 1.8rem;
  --font-size-h6: 1.4rem;

  // Headings line height
  --line-height-h1: 1.2;
  --line-height-h2: 1.3;
  --line-height-h3: 1.3;
  --line-height-h4: 1.4;
  --line-height-h5: 1.4;
  --line-height-h6: 1.5;

  // Headings letter spacing
  --letter-spacing-h1: normal;
  --letter-spacing-h2: normal;
  --letter-spacing-h3: normal;
  --letter-spacing-h4: normal;
  --letter-spacing-h5: normal;
  --letter-spacing-h6: normal;

  // Headings spacing top
  --spacing-h1-top: 5rem;
  --spacing-h2-top: 5rem;
  --spacing-h3-top: 5rem;
  --spacing-h4-top: 5rem;
  --spacing-h5-top: 5rem;
  --spacing-h6-top: 5rem;

  // Headings spacing bottom
  --spacing-h1-bottom: 2.5rem;
  --spacing-h2-bottom: 1.5rem;
  --spacing-h3-bottom: 1rem;
  --spacing-h4-bottom: 1rem;
  --spacing-h5-bottom: 1rem;
  --spacing-h6-bottom: 1rem;

  // lead-text
  --lead-text-font-family: inherit;
  --lead-text-font-size: 2rem;
  --lead-text-line-height: 1.5;
  --lead-text-letter-spacing: inherit;


  // Small
  --small-font-size: 1.4rem;
  --small-line-height: 1.5;
  --small-letter-spacing: inherit;

  // subtitle
  --subtitle-font-size: 1.8rem;
  --subtitle-line-height: 1.6;
  --subtitle-letter-spacing: inherit;

  // subtitle small
  --subtitle-small-font-size: 1.4rem;
  --subtitle-small-line-height: 1.5;
  --subtitle-small-letter-spacing: inherit;

  // Typography spacing
  --spacing-text-top: 1rem;
  --spacing-text-bottom: 1.5rem;
  --spacing-action-top: 2.5rem;
  --spacing-action-bottom: 2.5rem;
  --spacing-list-top: 1rem;
  --spacing-list-bottom: 1.5rem;
  --spacing-blockquote-top: 2.5rem;
  --spacing-blockquote-bottom: 2.5rem;

  // Media spacing
  --spacing-media-top: 5rem;
  --spacing-media-bottom: 5rem;
  --spacing-media-caption: 1rem;

  // Layout grid and container
  --container-max-width: 162.1rem;
  --container-max-skinny-width: 69rem;
  --container-max-slim-width: 93.5rem;
  --container-max-narrow-width: 118.2rem;

  // Middle
  --middle-max-width-small: 69rem;
  --middle-max-width-medium: 93.5rem;
  --middle-max-width-large: 118.2rem;

  // Grid
  --gutter: 1.5rem;
  --grid-gap: var(--gutter);
  --grid-align: start;
  --wrapper-gap: 3.1rem;
  --wrapper-gap-negative: calc(var(--wrapper-gap) * -1);

  // Section
  --section-spacing-s: 5rem;
  --section-spacing-m: 7.5rem;
  --section-spacing-l: 7.5rem;

  // Default section spacing
  --section-spacing: var(--section-spacing-l);

  // Font sizes base/mobile
  --heading-font-weight: 700;
  --heading-font-family: inherit;
  --heading-text-wrap: balance;

  // Shadow
  --shadow-000: 0 0 0 rgba(0, 0, 0, 0);
  --shadow-100: 0 .6rem .6rem rgba(0, 0, 0, 0.20);
  --shadow-200: 0 1.2rem 1.2rem rgba(0, 0, 0, 0.20);
  --shadow-300: 0 2.4rem 2.4rem rgba(0, 0, 0, 0.10);
  --shadow-400: 0 0 1rem rgba(0, 0, 0, 0.40);

  // Animation
  --animation-duration: 0.4s;
  --animation-ease: cubic-bezier(0.645, 0.045, 0.355, 1);

  // Link
  --link-color: var(--color-secondary-900);
  --link-color-hover: inherit;

  // Link decoration
  --link-font-weight: 700;
  --link-text-decoration: none;
  --link-text-decoration-hover: none;

  // Link inverted
  --marker-color: var(--color-primary-900);

  // Typography spacing
  --spacing-text: 2rem;
  --spacing-list: 3rem;
  --spacing-media: 3rem;

  // Lists
  --list-style-type: '-';
  --list-margin-top: 3rem;
  --list-item-padding-left: 1rem;
  --list-margin-left: .8rem;
  --list-item-margin-top: 1rem;

  // Ordered lists
  --ordered-list-margin-top: var(--list-margin-top);
  --ordered-list-margin-left: 1.9rem;
  --ordered-list-item-padding-left: 0.6rem;
  --ordered-list-item-margin-top: var(--list-item-margin-top);

  // Check-list
  @include background-svg.to-custom-property(svg.$svg-check-list, colors.$color-primary-800, --check-list);
  @include background-svg.to-custom-property(svg.$svg-check-list, colors.$color-white, --check-list-white);

  // Loading icon
  @include background-svg.to-custom-property(svg.$svg-loading, colors.$color-black, --loading-icon);
  @include background-svg.to-custom-property(svg.$svg-loading, colors.$color-white, --loading-icon-white);

  // Action and button
  --button-padding: 1rem 2rem;
  --button-font-size: 1.4rem;
  --button-min-width: 12.5rem;

  
  // Medium
  --button-m-padding: .8rem 2rem;
  --button-m-font-size: 1.8rem;
  --button-m-min-width: 12rem;


  // Large
  --button-l-padding: 1.7rem 2rem;
  --button-l-font-size: 2rem;
  --button-l-min-width: 17rem;


  // XL
  --button-xl-padding: 2.3rem 2.3rem;
  --button-xl-font-size: 2.2rem;
  --button-xl-min-width: 26rem;


  // Header
  --header-height: 8rem;
  --header-logo-text-fill: var(--color-primary-900);

  // Hamburger
  --hamburger-line-height: .1rem;
  --hamburger-padding: 4rem;
  --hamburger-size: 10rem;
  --hamburger-spacing: .5rem;
  --hamburger-line-color: var(--color-white);
  --hamburger-bg-color: var(--color-primary-900);

  // Forms and fields
  --form-row-gap: 0 1.5rem;

  // Label
  --label-font-size: 1.4rem;
  --label-line-height: 1.5;
  --label-padding: 0 0 .4rem;

  // Field
  --field-margin-bottom: 3rem;
  --field-error-font-size: 1.4rem;
  --field-error-line-height: 1.5;
  --field-error-color: var(--color-error);
  --field-error-margin: .4rem 0 0;

  // Input
  --input-font-size: 1.6rem;
  --input-line-height: 1.2;
  --input-padding-inline: 1.5rem;
  --input-padding-block: 1.3rem;
  --input-height: 5.1rem;
  --input-border-color: transparent;
  --input-border-width: .1rem;
  --input-outline-border-width: .1rem;
  --input-outline-padding-inline: 1.2rem;
  --input-outline-padding-block: 2rem;
  --input-border-radius: 0;
  --input-background-color: var(--color-grey-100);
  --input-transition: color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
  --input-select-padding-right: 4rem;
  --input-select-arrow-bg: var(--input-select-arrow-default);

  // Focus
  --input-focus-border-color: var(--color-grey-400);

  // Placeholder
  --input-placeholder-color: var(--color-grey-500);

  // Instructions
  --field-instructions-margin: .5rem 0;
  --field-instructions-font-size: 1.4rem;

  @include background-svg.to-custom-property(svg.$svg-chevron-down, colors.$color-black, --input-select-arrow-default);
  @include background-svg.to-custom-property(svg.$svg-chevron-down, colors.$color-error, --input-select-arrow-error);
  @include background-svg.to-custom-property(svg.$svg-chevron-down, colors.$color-white, --input-select-arrow-white);


  --check-size: 3rem;
  --check-bg-size: 2.2rem;
  --check-border-radius: .2rem;
  --check-bg-color: transparent;
  --check-color: var(--color-secondary-900);
  --check-border-width: .2rem;
  --check-border-color: currentColor;
  --check-transition: color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
  --label-option-padding-left: calc(var(--check-size) + 1rem);
  --label-option-font-size: 1.8rem;

  // Switch
  --switch-thumb-size: 2.5rem;
  --switch-width: 5rem;
  --switch-height: 2.5rem;
  --switch-border-radius: 5rem;
  --switch-track-bg-color: var(--color-grey-100);
  --switch-checked-bg-color: var(--color-secondary-900);
  --switch-hover-bg-color: var(--color-primary-800);
  --switch-bg-color: var(--color-primary-800);
  --switch-border-width: 0;
  --switch-border-color: currentColor;
  --switch-transition: color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);

  // Hover
  --check-hover-color: var(--color-black);

  // Checked
  --check-checked-color: var(--color-secondary-900);
  --check-checked-bg-color: currentColor;

  @include background-svg.to-custom-property(svg.$svg-check, colors.$color-white, --check-checked-bg-image);
  @include background-svg.to-custom-property(svg.$svg-radio, colors.$color-white, --check-checked-radio-bg-image);

  // Range slide
  --range-slider-track-color: var(--color-grey-100);
  --range-slider-thumb-color: var(--color-primary-900);
  --range-slider-thumb-size: 3rem;
  --range-slider-track-border-radius: .5rem;
  --range-slider-track-height: 1rem;
  --input-disabled-color: var(--color-disabled);

  // Line color
  --line-color: var(--color-grey-400);

  // Heading
  --heading-margin-bottom: 5rem;

  // Medium
  @include mq.mq($from: medium) {
    // add stuff

  }

  // Large
  @include mq.mq($from: large) {
    // Font sizes Desktop
    --font-size-h1: 4.8rem;
    --font-size-h2: 4rem;
    --font-size-h3: 2.8rem;
    --font-size-h4: 2.2rem;
    --font-size-h5: 1.8rem;
    --font-size-h6: 1.4rem;

    // line-height
    --line-height-h1: 1.2;
    --line-height-h2: 1.3;
    --line-height-h3: 1.3;
    --line-height-h4: 1.4;
    --line-height-h5: 1.4;
    --line-height-h6: 1.5;

    // Headings spacing top
    --spacing-h1-top: 7.5rem;
    --spacing-h2-top: 7.5rem;


    // Headings spacing bottom
    --spacing-h3-bottom: 1.5rem;
    --spacing-h4-bottom: 1.5rem;
    --spacing-h5-bottom: 1.5rem;
    --spacing-h6-bottom: 1.5rem;

    // Lead-text
    --lead-text-font-size: 2.4rem;

    // Header
    --header-height: 18rem;
    --header-meta-height: 9.5rem;
    --header-logo-max-height:4.5rem;
    --header-color: currentColor;    
    --header-logo-text-fill: var(--color-white);
    --wrapper-gap: 5.5rem;


    // Section
    --section-spacing-s: 7.5rem;
    --section-spacing-m: 12.5rem;
    --section-spacing-l: 17.5rem;

    // Action and button
    --button-padding: 1.5rem 2.5rem;
    --button-font-size: 1.8rem;
    --button-min-width: 19rem;
    --button-letter-spacing: inherit;

    // Heading
    --heading-margin-bottom: 7.5rem;

    // Input
    --input-padding-inline: 2.5rem;

  }
}


@media (prefers-reduced-motion: reduce) {
  :root {
    --animation-start-opacity: 1;
    --animation-duration: 0;
    --animation-ease: none;
    --scroll-behavior: auto;
  }
}