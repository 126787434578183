@use "../mixins/mq";
@use "../utils/visually-hidden";

.overlay-header {
  --header-color: var(--color-white);
  --header-bg-color-opacity: 0;
  --header-margin-bottom: calc(var(--header-height) * -1);
}


@include mq.mq($until: large) {
  .open-mobile-menu {
    --header-nav-overlay-display: block;

    overflow: hidden;
  }

  .visible-mobile-menu {
    --header-content-visibility: visible;
  }
}

.header {
  color: var(--header-color, inherit);
  width: 100%;
  padding: 0;
  z-index: 3;
  position: relative;
  

  &__wrapper {
    display: block;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    height: var(--header-height);
    margin-inline: auto;
    max-width: 135rem;
  }

  &__home-link {
    color: inherit;
  }

  &__logo {
    display: block;
    width: auto;
    max-height: var(--header-logo-max-height,3.2rem);

  }

  &__logo-text {
    fill: var(--header-logo-text-fill);
  }

  &__hamburger {
    margin: auto;
    position: relative;
    z-index: 3;
    margin-right: var(--wrapper-gap-negative);
  }


  &__search-button {
    display: none;
    color: currentColor;
  }

  &__quick-search {
    padding-block: 2.5rem;
    padding-inline: var(--wrapper-gap) calc(10rem + var(--wrapper-gap));
    border-bottom: .1rem solid var(--color-grey-400);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
    z-index: 1;
    display: block;
  }

  &__nav-meta {
    display: flex;
    flex-direction: column;
    gap: 2.7rem 2.5rem;
  }


  &__mobile-actions {
    display: flex;
    gap: 3.3rem;
    align-items: center;
  }

  &__nav-backdrop {
    display: none;
  }


  @include mq.mq($until: large) {
    &__content {
      // medium small and medium only
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      padding: 0;
      visibility: var(--header-content-visibility, hidden);
      display: block;
      z-index: 2;
      transform: translate3d(-100%, 0, 0);
      overflow: hidden auto;
      -webkit-overflow-scrolling: touch;
      transition: transform var(--animation-duration) var(--animation-ease);
      color: currentColor;
      background-color: var(--color-white);

      
      .open-mobile-menu & {
        transform: translate3d(0, 0, 0);
      }

    }

    &__nav {
      position: relative;
      min-height: 100vh;
    }


  }

  &__content {
    display: block;

    @include mq.mq($from: medium, $until: large) {
      max-width: 50rem;
    }
  }



  &__nav-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-black-transparent);
    display: var(--header-nav-overlay-display, none);
    z-index: 1;
  }
  

}



@include mq.mq($from: large) {
  .header {
    border-bottom: .1rem solid var(--color-primary-900);

    &__home-link {
      position: absolute;
      margin-block: 2.5rem;
    }

    &__container {
      display: block;
    }

    &__nav {
      height: var(--header-height);
      display: flex;
      padding: 0;
      flex-direction: column-reverse;
    }

    &__nav-meta {
      background-color: var(--color-primary-900);
      color: var(--color-primary-800);
      padding: 2.5rem 0 2.5rem 30rem;
      height: var(--header-meta-height);
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      
      // meta font 
      font-size: var(--small-font-size);
      letter-spacing: var(--small-letter-spacing);
      line-height: var(--small-line-height);

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        z-index: -1;
        height: var(--header-meta-height);
        background-color: var(--color-primary-900);
      }
    }


    &__lang-nav {
      &--mobile {
        display: none;
      }

      &--large {
        display: inline-flex;
      }
    }

    &__mobile-actions {
      display: none;
    }

    &__hamburger {
      display: none;
    }

    &__search-button {
      display: block;

        &:hover,
        &:focus {
          color: var(--color-white);
        }
    }


    &__quick-search {
      padding-block: 3.6rem;
      background-color: var(--color-white);
      padding-inline: var(--wrapper-gap);
      transform: translateY(-100%);
      transition: transform var(--animation-duration) var(--animation-ease);

      &:popover-open {
        transform: translateY(0);
      }

      &::backdrop {
        background-color: var(--color-black-transparent);
      }
    }


    &__nav-backdrop {
      display: none;
      background-color: var(--color-black-transparent);
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      height: 100vh;
      z-index: -1;

      &--show {
        display: block;
      }
    }

  }
}