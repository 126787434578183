@use "../mixins/mq";

.is-active,
.is-visible {
    --hero-slider-cover-clip-path: inset(0 0 0 0);
    --hero-slider-cover-opacity: 1;
    --hero-slider-slide-opacity: 1;
    --hero-slider-slide-z-index: 1;
    --hero-slider-cover-object-view-box: inset(0% 0% 0% 0%);
    --hero-slider-transition-delay: 0s;
    --hero-slider-nav-link-color: var(--color-white);
}

.hero-slider {
    --spacing-h2-bottom: 2.5rem;
    --img-object-view-box: inset(0% 0% 0% 0%);
    --splide-pagination-margin: 0;
    --splide-accent-color: var(--color-white-400-transparent);

    color: var(--color-white);
    background-color: var(--color-primary-900);
    position: relative;

    &__content {
        flex: 1;
    }

    &__aside {
        background-color: var(--color-white);
        padding-left: var(--wrapper-gap);
    }

    &__cover {
        transform: translateX(var(--slider-drag-offset, 0));
        clip-path: var(--hero-slider-cover-clip-path, inset(0 0 0 100%));
        opacity: var(--hero-slider-cover-opacity, 0);
        transition: transform var(--animation-duration) var(--animation-ease), opacity var(--animation-duration) var(--animation-ease), clip-path var(--animation-duration) var(--animation-ease);
        transition-delay: var(--hero-slider-transition-delay, .4s);
    }

    &__heading {
        padding: 5rem var(--wrapper-gap);
      
    }

    &__link {
        display: block;
        color: inherit;
    }

    &__image {
        margin-left: auto;
        width: 100%;
        -webkit-user-drag: none;
    
        &--placeholder {
            aspect-ratio: 8.5/7;
            background-color: var(--color-black-transparent);
        }
    }

    &__footer {
        padding: 2.5rem var(--wrapper-gap);
    }

    &__scroll-indicator {
        display: none;
    }

    &__progress {
        display: none;       
        background-color: var(--color-white-100-transparent);
    }

    &__progress-bar {
        height: .1rem;
        transform-origin: left;
        background-color: var(--splide-accent-color);
        animation: slider-progress var(--hero-slider-interval,7000ms) linear;
    }


    &__nav {
        overflow: hidden;
        display: none;
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        white-space: nowrap;
        color: var(--color-primary-800);

    }

    &__nav-item {
        --duration: var(--hero-slider-index-duration, var(--animation-duration));

        margin: 0;
        padding: 0;
        width: 50%;
        line-height: 2;
        display: inline-block;

        &:first-child {
            transition: margin-left var(--duration) var(--animation-ease);
            margin-left: calc( -50% * var(--hero-slider-index));
        }
    }

    &__nav-link {
        font-weight: inherit;
        color: var(--hero-slider-nav-link-color,inherit);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        padding-right: 5rem;

        &:hover {
            --hero-slider-nav-link-color: var(--color-white);
        }
    }

    @include mq.mq($from: medium) {
        --spacing-h2-bottom: 3rem;

        &:has(.hero-slider__link:hover) {
            --img-object-view-box: inset(0% 5% 0% 0%);
        }

        &__slide {
            display: flex;
            flex-direction: row-reverse;
            opacity: var(--hero-slider-slide-opacity,0);
            transition: opacity var(--animation-duration) var(--animation-ease);
            position: relative;
            z-index: var(--hero-slider-slide-z-index,0);
        }

        &__content {
            display: grid;
            align-items: center;
            margin-bottom: 25rem;
        }

        /* tmp */
        &__list {
            display: grid !important;
            grid-template-areas: 'wrapper';

            >li {
                grid-area: wrapper;
            }
        }

        &__progress {
            display: block;
        }

        &__aside {
            padding: 0;
            flex: 1;

        }
   
        &__image {
            height: calc(100vh - var(--header-height));

            &--placeholder {
                aspect-ratio: auto;

            }
        }

        &__footer {
            position: absolute;
            bottom: 0;
            z-index: 1;
            width: 50%;
            padding: 0;
        }

        &__controls {
            padding: 0 6rem;
            height: 12rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 5rem;
        }

        &__scroll-indicator {
            display: block;
        }

        &__nav {
           display: block;
        }

    }

    @include mq.mq($from: large) {
        &__link {
            margin-left: auto;
            max-width: calc( 59rem - var(--wrapper-gap));
            display: block;
            width: 100%;
        }

        &__nav {
            max-width: 54.8rem;
        }

        &__heading {
            padding: 5rem 14rem;
          
        }

    }
}



@keyframes slider-progress {
    from {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
}
