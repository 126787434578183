.play {
    margin: auto 0;
    border-radius: 50%;
    border: 0.2rem solid var(--color-secondary-900);
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all var(--animation-duration) var(--animation-ease);

    &--paused {
        --play-icon-play-display: none;
        --play-icon-pause-display: block;
    }

    &__icon {
        &--play {
            display: var(--play-icon-play-display, block);
        }

        &--pause {
            display: var(--play-icon-pause-display, none);
        }
    }

    &:hover {
        transform: scale(1.16);
    }

    &:focus, &:hover {
        --link-color: var(--color-secondary-900);
    }

}
