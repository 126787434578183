.icon {
	position: var(--icon-position, relative);
	display: var(--icon-display, block);
	font-style: normal;
	color: var(--icon-color, currentColor);
	width: var(--icon-width, auto);
	min-width: var(--icon-width, none);
	height: var(--icon-height, auto);
	flex-shrink: 0;
	transform: var(--icon-transform, none);
	max-height: 100%;
	margin: var(--icon-margin, none);
	vertical-align: var(--icon-vertical-align, inherit);

	&--small {
		--icon-width: 3.2rem;
		--icon-height: 3.2rem;
	}

	&--large {
		--icon-width: 7.5rem;
		--icon-height: 7.5rem;
	}

}
