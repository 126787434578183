@use "../mixins/mq";

.partner {
    --img-width: 20rem;

    display: grid;
    gap: 2.5rem var(--grid-gap);

    &__infos {
        &-link {
            margin-top: 2.5rem;
        }
    }

    @include mq.mq($from: large) {
        grid-template-columns: repeat(7, 1fr);

        &__logo {
            grid-column-end: span 2;
        }

        &__infos {
            grid-column: 3 / span 5;

            &-link {
                margin-top: 3rem;
            }
        }
    }
}