.search {
   &__result-text {
      margin-bottom: 2.5rem;
   }

   &__result-list {
      margin: 0;
      padding: 0;
      list-style: none;
   }

   &__result-item {
      margin: 0;
      padding: 0;
   }

   &__pager {
      margin-top: 12.5rem;
   }

   &__form {
      --link-color: currentColor;
      --input-background-color: transparent;
      --field-margin-bottom: 0;
      --input-placeholder-color: var(--color-primary-800);
   }

}