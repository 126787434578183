.scroll-indicator {
    --size: 1.6rem;
    --stroke-width: .2rem;

    position: relative;
    width: var(--size);
    aspect-ratio: 16/25;
    border: var(--stroke-width) solid var(--color-white-200-transparent);
    border-radius: 5rem;
    display: grid;
    justify-items: center;
  
    &:after {
        content: '';
        width: var(--stroke-width);
        height: 33%;
        margin-top: 40%;
        background-color: var(--color-white);
        border-radius: 1rem;
        animation: scroll-indicator 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    }
}

@keyframes scroll-indicator {
    0% {
        transform: scale(1, 0);
        transform-origin: 0 0
    }

    25% {
        transform: scale(1, 1);
        transform-origin: 0 0
    }

    30% {
        transform: scale(1, 1);
        transform-origin: 0 100%
    }


    50%,
     100% {
        transform: scale(1, 0);
        transform-origin: 0 100%
    }
}