@use "../mixins/mq";
@use "../_base/typography";
@use "../atoms/action";

.footer__bottom {
  padding-block: 2.5rem;
  background-color: var(--color-grey-300);

  @include mq.mq($from: medium) {
    padding: 3.1rem 0;
  }
}