@use "../mixins/mq";

.video-element {
    text-align: center;

    &__controls {
        --icon-width: 4.5rem;

        list-style: 1;
        display: flex;
        gap: 1.5rem;
        align-items: center;
        order: 2;
        margin-top: 2.5rem;
    }

    @include mq.mq($from: medium) {
        margin-right: 0;

        &__controls {
            --icon-width: 5.2rem;
            
            order: initial;
            flex-direction: column;
            height: 100%;
            margin: 0;
            justify-content: center;

            &:before {
                content: "\00a0";
                content: "\00a0" / "";
            }
        }
    }
}