// for section with dark background
.inverted {
    --check-list: var(--check-list-white);
    --line-color: var(--color-white-400-transparent);
}

.current-color {
    --link-color: currentColor;
    --link-color-focus: currentColor;
    --color-h1: currentColor;
}