@use "../mixins/mq";

.nav--meta-nav {
	--link-color: currentColor;
	--link-text-decoration: none;
	--link-font-weight: normal;
	--link-color-hover: var(--color-secondary-900);

	padding: 0 var(--wrapper-gap) 15rem;

	.is-active {
		--link-color: var(--color-secondary-900);
	}

	.nav {
		&__item {
			display: grid;
		}

		&__wrap {
			&--parent {
				display: flex;
				gap: 1.5rem 2.5rem;
				flex-direction: column;
			}
		}
	}


	@include mq.mq($from: large) {
		--link-color-hover: var(--color-white);

		padding: 0;

		.is-active {
			--link-color: var(--color-white);
		}

		.nav {
			&__item {
				display: grid;
			
			}

			&__wrap {
				&--parent {
					flex-direction: row;
					align-items: center;
				}

			}
		}
	}

}