@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";

.nav {
	--link-text-decoration-active: none;
	
	display: block;

	&__wrap {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__item {
		padding: 0;
		margin: 0;
	}
}