
.range-slider {
    appearance: none;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    height: var(--range-slider-track-height);
    background-color: var(--range-slider-track-color);
    border-radius: var(--range-slider-track-border-radius);

    [disabled],
    .disabled {
      --range-slider-track-color: var(--input-disabled-color);
      --range-slider-thumb-color: var(--input-disabled-color);
    
    }
}

::-moz-range-thumb {
    position: relative;
    z-index: 1;
    appearance: none;
    pointer-events: all;
    width: var(--range-slider-thumb-size);
    height: var(--range-slider-thumb-size);
    background-color: var(--range-slider-thumb-color);
    border-radius: 50%;
    cursor: pointer;
    transition: box-shadow .5s;
    border: none;
}


::-webkit-slider-thumb {
    position: relative;
    z-index: 1;
    appearance: none;
    pointer-events: all;
    width: var(--range-slider-thumb-size);
    height: var(--range-slider-thumb-size);
    background-color: var(--range-slider-thumb-color);
    border-radius: 50%;
    cursor: pointer;
    transition: box-shadow .5s;
    border: none;
}

