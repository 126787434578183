@use "../mixins/mq";

.accordion {
  min-width: 100%;
  padding: 2.5rem 3.1rem;
  counter-increment: accordion;
  border-bottom: 0.1rem solid var(--color-grey-400);
  margin-inline: var(--wrapper-gap-negative);

  &:first-child {
    border-top: 0.1rem solid var(--color-grey-400);
  }





  &__title {
    position: relative;
    display: flex;
    cursor: pointer;
    margin: 0;


    span {
      transition: transform var(--animation-duration) var(--animation-ease);
      display: inline-block;
      padding-right: 1.5rem;
      flex: 1;
    }

    &:before {
      content: counters(accordion, "", decimal-leading-zero);
      font-size: 1.4rem;
      letter-spacing: 0.028rem;
      padding-right: 1rem;
      width: 3.2rem;
      margin-top: 1.3rem;

    }

    .icon {
      transition: transform var(--animation-duration) var(--animation-ease);

    }

  }

  &__content {
    margin-top: 2.5rem;
    padding-left: 0;

  }

  &[open] {
    .icon {
      transform: rotate(180deg);
    }
  }

  @include mq.mq($from: medium) {
    padding: 3rem 3rem 3rem 0;
    margin-inline: 3rem 0;

    &__title {
      align-items: flex-start;
      margin-left: -3rem;

      &:before {
        padding-right: 1.5rem;
        margin-top: 0.75rem;

      }

      .icon {
        margin-top: 1rem;
      }

      &:hover {
        span {
          transform: translateX(0.5rem);

        }

      }
    }

    &__content {
      padding-left: 2rem;
      margin-top: 3rem;
    }
  }

  @include mq.mq($from: large) {
    padding: 3rem 0;
  }

}