@use "../mixins/mq";

.pull-edge {
    &-left {
        margin-left: var(--wrapper-gap-negative);
    }
    
    &-right {
        margin-right: var(--wrapper-gap-negative);
    }

    @include mq.mq($from: medium) {
        &-left {
            margin-left: 0;
        }

        &-right {
            margin-right: 0;
        }
    }
}