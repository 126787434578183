@use "../mixins/mq";


.course-information {
    &__name {
        font-weight: inherit;
        color: inherit;
    }

    // invert logic, break table on small
    @include mq.mq($until: medium) {
        &,
        &__body,
        &__item,
        &__cell {
            display: block;
            padding: 0;
        }

        &__item {
            padding-block: 2.5rem;
        }
     
        &__head {
            display: none;
        }
        
    }

    @include mq.mq($from: medium) {
        &__cell {
            padding: 2.5rem 1.5rem 5rem;
            width: 30%;
            
            &--name {
                width: 40%;
            }

            &--heading {
                vertical-align: bottom;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

}